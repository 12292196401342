import {useEffect} from 'react'
import { useHistory } from "react-router-dom"
import {HomeCont, ApiText, TxtIconsCont, TxtIcon} from './homeStyles'
import SearchBar from '../forms/SearchBar'
import HomeSlider from './HomeSlider'
import { FiCheck, FiHeart, FiAward, FiDollarSign, FiInfo } from 'react-icons/fi'
import Footer from '../footer/Footer'
        
export default function Home({
    dataList,
    setDataList,
    setItems,
    apiEvent,
    setApiEvent,
    mongo,
    itemColls,
    setFromHome
}){

    useEffect(() => {
        setApiEvent({        
        name: 'home',
        text: 'Discover minerals from around the world.',
        loading: false})
    }, [setApiEvent])

    return<HomeCont>
        <div style={{margin:'25px 0'}}>
            <SearchBar {...{
                            dataList,
                            setDataList,
                            apiEvent,
                            setApiEvent,
                            mongo,
                            useHistory,
                            setItems,
                            setFromHome
                        }} 
                    />
            <ApiText>
                <FiInfo color={'rgba(14,22,38,0.5)'} size={24} style={{marginRight:'5px'}} /> 
                {apiEvent.text}
            </ApiText>
        </div>
        <HomeSlider {...{
            useHistory,
            itemColls,
            setItems,
            setFromHome
            }} />
        <TxtIconsCont>
            <TxtIcon><FiCheck color={'lime'} size={60} /><p>I specialise in buying rare, exceptional and strange specimens.</p></TxtIcon>
            <TxtIcon><FiHeart color={'red'} size={60} /><p>I run my business with a great passion for minerals and with a love of people.  I want my customers to be overjoyed with their purchases.</p></TxtIcon>
            <TxtIcon><FiAward color={'orange'} size={60} /><p>Every specimen I sell comes with a lifetime guarantee that it is what I have claimed it to be.</p></TxtIcon>                
            <TxtIcon><FiDollarSign color={'green'} size={60} /><p>I also buy collections.</p>  </TxtIcon>              
        </TxtIconsCont>
        <Footer />
    </HomeCont>
}