import {useState} from 'react'
import {CollCont, BtnCont, Select, Option} from './itemsCollStyles'
import {updateOne} from '../realm/insertUpdateFind'
import Btn from '../buttons/Btn'
import {FiEdit, FiFileMinus } from 'react-icons/fi'

export default function ItemCollections({
    item,
    itemColls, 
    setItemColls,
    apiEvent,
    setApiEvent,
    mongo,
    _id
}){

    const [valGroup, setValGroup] = useState('')
    const [valRemove, setValRemove] = useState('')
    const [valPos, setValPos] = useState(0)

    let changeHandler = e => {
        if(isNaN(e.target.value)){
            setValGroup(e.target.value)
        }else{ 
            setValPos(Number(e.target.value))
        }
    }

    let changeHandlerRemove = e => {
        setValRemove(e.target.value)
    }

    let successUpdateCallback = (success, result) => {
        setApiEvent(state => ({...state, loading: false}))
        if(success){
            setItemColls(state => {
                let s = state[valGroup].slice(0)
                s.splice(valPos, 0, _id)
                return {...state, [valGroup]:s}
            })
            setItemColls(state => ({...state, [valGroup + 'Itms']:[...state[valGroup + 'Itms'], item]}))
            setValGroup('')
            setValPos(0)
        }
        else console.log(result)
    }
    let successRemoveCallback = (success, result) => {
        setApiEvent(state => ({...state, loading: false}))
        if(success){
            setItemColls(state => ({...state, [valRemove]:[...state[valRemove].filter(id => (_id.toString() !== id.toString()))]}))
            setValRemove('')
        }
        else console.log(result)
    }

    let addItem = e => {
        e.preventDefault()
        setApiEvent(state => ({...state, name:'add', loading: true}))
        if(valGroup === '') return
        let insertValAt = {
            "$push": {
                "list": {
                    "$each": [_id],
                    "$position":valPos
                 }
             }
        }
        updateOne(mongo, 'item_collections',{name:valGroup}, insertValAt, successUpdateCallback )
    }

    let removeItem = e => {
        e.preventDefault()
        if(valRemove === '') return
        setApiEvent(state => ({...state, name:'delete', loading: true}))
        let removeValAt = {"$pull":{"list":_id}}
        
        updateOne(mongo, 'item_collections',{name:valRemove}, removeValAt, successRemoveCallback )
    }

    return <CollCont>
        <BtnCont>
            Add item to a group: 
            <Select
            value={valGroup}
            onChange={changeHandler}
            >
                <Option value={''} disabled>Select a group:</Option>
                {itemColls.names.map((name,i) => {

                    if(itemColls[name].some(id => (_id.toString() === id.toString()))){

                        return <Option disabled key={'op'+i} value={name}>{name}</Option>
                    }
                    else{
                       return <Option key={'op'+i} value={name}>{name}</Option> 
                    }
                })}
            </Select>
            <Select
            value={valPos}
            onChange={changeHandler}
            >
                <Option value={0} disabled>Select a position:</Option>
                {valGroup && itemColls[valGroup].map((_,i) => (<Option key={'opti'+i+1} value={i+1}>{i+1}</Option>))}

            </Select>
            <Btn 
            text={'ADD'} 
            maxW={'200'}
            click={addItem}
            loading={apiEvent.name === 'add' && apiEvent.loading} 
            icon={<FiEdit color={'black'} size={21} />}
            />
        </BtnCont>
        <BtnCont>
            Remove item from a group: 
            <Select
            value={valRemove}
            onChange={changeHandlerRemove}
            >
                <Option value={''} disabled>Select a group:</Option>
                {itemColls.names.map((name,i) => {

                    if(itemColls[name].some(id => (_id.toString() === id.toString()))){

                        return <Option key={'op'+i} value={name}>{name}</Option>
                    }
                    else{
                        return <Option disabled key={'op'+i} value={name}>{name}</Option> 
                    }
                })}
            </Select>
            <Btn 
            color={'white'} 
            bg={'rgba(242,	52,	57, 1)'} 
            text={'REMOVE'}
            maxW={'200'}
            click={removeItem}
            loading={apiEvent.name === 'delete' && apiEvent.loading}
            icon={<FiFileMinus color={'black'} size={21} />}
            />
        </BtnCont>
    </CollCont>
}