import {MainCont, HeaderImgCont} from './aboutStyles'
import {Image, Transformation} from 'cloudinary-react'
import Footer from '../footer/Footer'

export default function About(){

    return(
        <MainCont>
            <header className={'header'}>About me & Rinchen Minerals.com</header>
            <HeaderImgCont>
                <article className={'img-tall-article'}>
                    <Image cloud_name='rinchen' publicId={'Miscellaneous/bald108 '} width='100%'>
                        <Transformation 
                        width="200"                 
                        crop="scale" 
                        quality="auto" 
                        fetchFormat="auto"  
                        />
                    </Image>
                </article>
                    <article className={'img-row-article'}>
                        <Image cloud_name='rinchen' publicId={'Beryls/Aquamarine-GC120A_10'} width='50%'>
                            <Transformation
                            width="270"                 
                            crop="scale" 
                            quality="auto" 
                            fetchFormat="auto" 
                            />
                        </Image>
                        <Image cloud_name='rinchen' publicId={'Quartz/Quartz-DJ2333A_1'} width='50%'>
                            <Transformation
                            width="270"                       
                            crop="scale" 
                            quality="auto" 
                            fetchFormat="auto" 
                            />
                        </Image>
                        <Image cloud_name='rinchen' publicId={'Tourmaline/Tourmaline-SSB042A_2'} width='50%'>
                            <Transformation
                            width="270" 
                            crop="scale" 
                            quality="auto" 
                            fetchFormat="auto"
                            />
                        </Image>
                        <Image cloud_name='rinchen' publicId={'Apatite/Apatite-DJ2140J_1'} width='50%'>
                            <Transformation
                            width="270"                         
                            crop="scale" 
                            quality="auto" 
                            fetchFormat="auto" 
                            />
                        </Image>                       
                    </article>
            </HeaderImgCont>
            <main className={'main'}>
                <article className={'article'}>
                    <header>My name is Jhinpa Ramos-Gonzalez FGA MICCH</header>
                    <p>I have always had a love of minerals, particularly their colours and crystal shapes.  Some of my earliest memories are of my fascination for the pebbles on the beach and the rocks in the mountains.</p>
                    <p>My appreciation turned into full-on addiction – I could not go long without having to buy a beautiful specimen.  The best way to fund my addiction was to buy and sell minerals so, in 1990, I set up Rinchen Minerals.</p>
                    <p>I had already read Robert Webster’s book “Gems” from cover to cover when I started formally studying gemmology with the Gemmological Association of Great Britain (Gem-A). </p> 
                    <p>I became a fellow of the Association (FGA) in 1994.  I went on to teach the diploma practical class, for them, at the John Cass School of Art, which was part of the Guildhall University in London.</p>
                    <p>I am honoured to have met many great gemmologists and can call several of them friends – Christopher Cavey, Nigel Israel, the late Alan Jobbins, the late Jamie Nelson, etcetera.</p>
                    <p>I attended Alan Jobbin’s post diploma course for many years – until he retired.  Following this, I was a founding member of the wonderful JPH (jewellery, plate & horology) club – which was organised by Alan Jobbins and Nigel Israel.</p>
                    <p>Directly after achieving my gemmology diploma I started to train as a crystal healer with the International College of Crystal Healing (ICCH).  This was a thorough and in-depth course that included “year one” and “year two” but, in reality, spanned about four years.  I received my diploma and became an MICCH in 1998.  Since then I have taken part in courses of advanced crystal work with the ICCH.</p>
                    <p>I retired in 2006 so that I could spend more time with and give more support to my first wife, Christina.  Sadly Christina died.  I moved to Germany and started my business up again.</p>
                    <p>I specialise in buying rare, exceptional and strange specimens.</p>
                    <p>I run my business with a great passion for minerals and with a love of people.  I want my customers to be overjoyed with their purchases.</p> 
                    <p>Unfortunately we occasionally make mistakes.  So, any specimen I sell comes with a lifetime guarantee that it is what I have claimed it to be.</p>
                </article>
            </main>
            <Footer />
        </MainCont>
    )
}