import styled, {keyframes} from 'styled-components'

const spin = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}`

export const TopCont = styled.div` 
    padding:5px 30px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.2);
    border-top:1px solid rgba(14,22,38,0.5);
    background:rgba(239,41,41,0.03);
`
export const Title = styled.span`
    display:flex;
    align-items:center;
    width:100%;
    font-size: clamp(0.8rem, 2.2vw, 1.1rem);
    color:rgba(239,41,41,1);
`
export const BotCont = styled.div` 
    width:100%;
    max-height:35vh;
    display:flex;
    flex-direction:column;
    overflow-y:auto;
`
export const Ul = styled.ul` 
    width:100%;
    > * {
      &:last-child {
		border-bottom: 1px solid rgba(14,22,38,0.2);
      }
    }
`
export const OpenItem = styled.div`
    display:flex;
    width:100%;
	cursor:pointer;
    background:rgba(239,41,41,0.03);

    &:hover{
        background:rgba(239,41,41,0.01);
    }
`
export const Li = styled.li` 
    width:100%;
    display:flex;
    border-radius:3px;
    border-top:1px solid rgba(14,22,38,0.2);
`
export const FavTitle = styled.span`
    padding:10px;
    display:flex;
    align-items:center;
    flex-grow:1;
`
export const Remove = styled.span`
    //position:relative;
    width:50px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:10px;
	cursor:pointer;
    background:rgba(14,22,38,0.1);

&:hover{
    background:rgba(14,22,38,0.15);
}
`
export const Loader = styled.div`
    //position:absolute;
	border: 2px solid white;
	border-top: 2px solid rgba(0, 0, 0, 1);
	border-radius: 50%;
	width:25px;
	height:25px;
	animation: ${spin} 1s linear infinite;
`
