import { useState, useRef, useEffect } from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { SliderCont, OverflowCont, DefineHeight, LeftSlideCont, MidSlideCont, LoaderCont, Loader, ThumbsCont, Thumb, SlideLeftBtn, SlideRightBtn } from './sliderStyles'
import { useSpring } from '@react-spring/web'
import { FiChevronLeft, FiChevronRight} from 'react-icons/fi'

export default function Slider({ imageArr, urlClick }) {

    const [counter, setCounter] = useState(0)
    const [oldCounter, setOldCounter] = useState(0)
    const [leftRight, setLeftRight] = useState(false)
    const [loader, setLoader] = useState(true)

    const slideRest = useRef(true)
    const skipIntro = useRef(false)

    const [slider, setSlider] = useSpring(() => ({left:'0%'}))

    let isUrl = urlClick ? true : false

    let slide = indx => e => {
        e.preventDefault()

        if (indx === counter || oldCounter === indx || 
            indx >= imageArr.length || indx < 0 || !slideRest.current) return
        
        indx > counter ? setLeftRight(true) : setLeftRight(false)
        setLoader(true)
        setCounter(indx)
        slideRest.current = false
    }

    let loaded = direction => {
        setLoader(false)
        skipIntro.current && setSlider.start({left:direction === 'left' ? '-100%' :'100%', onRest: () => {
            setOldCounter(counter)
            setSlider.start({left:'0%', immediate:true})
            slideRest.current = true
        }})

        skipIntro.current = true
    }

    let sides = (counters, direction) => (
        direction
        ?   <Image onLoad={() => loaded(direction)} cloud_name='rinchen' publicId={imageArr[counters]}  style={{width:'100%'}}>
                <Transformation
                quality="auto" 
                fetchFormat="auto" 
                width="1024" 
                crop="scale"
                />
            </Image>
        :   <Image cloud_name='rinchen' publicId={imageArr[counters]}  style={{width:'100%'}}>
                <Transformation
                quality="auto" 
                fetchFormat="auto" 
                width="1024" 
                crop="scale"
                />
            </Image>
    )

    useEffect(() => {
        setCounter(0)
        setOldCounter(0)
	}, [imageArr])

    return <>
        <OverflowCont>
            <SliderCont style={slider} onClick={isUrl ? urlClick(counter): null} urlon={isUrl.toString()}>
                <DefineHeight>
                    {sides(counter)}
                </DefineHeight>
                <LeftSlideCont leftRight={leftRight}>
                    {sides(counter, leftRight ? 'left' : 'right')}
                </LeftSlideCont>
                <MidSlideCont>
                    {sides(oldCounter)}
                    {
                        loader && 
                        <LoaderCont>
                            <Loader />
                        </LoaderCont>
                    }
                </MidSlideCont>
            </SliderCont>
            <SlideLeftBtn startEnd={counter <= 0 ? false : true} onClick={slide(counter-1)}>
                <FiChevronLeft color={'white'} size={36} />
            </SlideLeftBtn>
            <SlideRightBtn startEnd={counter >= imageArr.length-1 ? false : true} onClick={slide(counter+1)}>
                <FiChevronRight color={'white'} size={36} />
            </SlideRightBtn>
        </OverflowCont>
        <ThumbsCont>
            {imageArr.map((img, i) => (
                <Thumb 
                key={`${i}tmb`} 
                onClick={slide(i)} 
                selected={i === counter ? true : false}
                >
                    <Image cloud_name='rinchen' publicId={img}>
                        <Transformation 
                        effect="sharpen:70" 
                        width="100" 
                        crop="scale" 
                        quality="auto" 
                        fetchFormat="auto"
                        />
                    </Image>
                </Thumb>
            ))}
        </ThumbsCont>
    </>
}