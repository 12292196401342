import styled from 'styled-components'

export const Ul = styled.ul`
    padding:15px 0;
    //padding-right:0;
    margin:15px;
`
export const Li = styled.li`
    padding:10px 0;
    display:flex;
    align-items:center;
    background:rgba(14,22,38,0.5);
    color:rgba(255,255,255,0.7);
    margin-bottom:1px;
    cursor:pointer;

    &:hover{
        background:rgba(14,22,38,1);
        color:white;
    }

    &:first-child{
        border-top:3px solid rgba(14,22,38,1);
        margin-top:10px;
    }

`