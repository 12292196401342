import {useHistory} from "react-router-dom"
import {userAnon} from '../realm/realm'
import {Ul, Li} from './sideContStyles'
import { FiLogIn, FiLogOut, FiUserPlus, FiHeart, FiDollarSign, FiLifeBuoy, FiHelpCircle, FiLock } from 'react-icons/fi'


export default function SideCont({
    setIsSignIn, 
    setOpenSignIn, 
    setSideNavOpen,
    verified,
    setVerified,
    setOpenVerified,
    mongoApp,
    setMongo,
    setMongoApp,
    setMongoUser,
    setAdmin,
    btnMin,
    setBtnMin,
    setOpenCart,
    openCart,
    setPurchHist
}){
    const history = useHistory()

    const LogOut = async e => {
        e.preventDefault()
        await mongoApp.currentUser.logOut()
        setOpenVerified(false)
        setVerified({})
        setAdmin(false)
        setBtnMin(false)
        setSideNavOpen(false)

        userAnon().then( db => {
            setMongo(db.mongo)
            setMongoApp(db.app)
            setMongoUser(db.user)
        })
    }

    return<>
        {verified.id 
            ? <Ul> Welcome, {verified.customData.name}
                    <Li onClick={e => {
                            e.preventDefault()
                            setOpenCart(true)
                            setOpenVerified(state => (!openCart ? true : !state))
                            setSideNavOpen(false)
                        }}
                    >
                        <FiHeart color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                        Favorites
                    </Li>            
                    <Li onClick={e => {
                        e.preventDefault()
                        setPurchHist(true)
                        setOpenCart(true)
                        setSideNavOpen(false)
                    }}
                    >
                        <FiDollarSign color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                        Purchase history
                    </Li>
                    <Li onClick={LogOut}>
                        <FiLogOut color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                        Sign Out
                    </Li>
                </Ul>
            : btnMin
                ?   <Ul> 
                        Signed in as Admin
                        <Li onClick={LogOut}>Sign Out</Li>
                    </Ul>                                
                :   <Ul> Welcome!
                    <Li onClick={e => {
                        e.preventDefault()
                        setIsSignIn(false)
                        setOpenSignIn(true)
                        setSideNavOpen(false)
                        }}
                    >
                        <FiUserPlus color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                        Sign Up
                    </Li>
                    <Li onClick={e => {
                        e.preventDefault()
                        setIsSignIn(true)
                        setOpenSignIn(true)
                        setSideNavOpen(false)
                        }}
                    >
                        <FiLogIn color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                        Sign In
                    </Li>
                </Ul>
        }
        <Ul> Support & Info.
            <Li onClick={e => {
                e.preventDefault()
                history.push(`/about`)
                setSideNavOpen(false)
                }}
            >
                <FiHelpCircle color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                About
            </Li>
            <Li onClick={e => {
                e.preventDefault()
                history.push(`/support`)
                setSideNavOpen(false)
                }}
            >
                <FiLifeBuoy color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                Support
            </Li>
            <Li onClick={e => {
                e.preventDefault()
                history.push(`/privacy`)
                setSideNavOpen(false)
                }}
            >
                <FiLock color={'rgba(255,255,255,1)'} size={20} style={{marginRight:'10px'}}/>
                Privacy
            </Li>
        </Ul>
    </>

}