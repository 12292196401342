import styled from "styled-components"
import {animated} from "@react-spring/web"
//import media from '../media'

export const OverlayCont = styled.div`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    justify-content:center;
    margin-top:50px;
`
export const Overflow = styled(animated.div)`
    position:relative;
    width:clamp(567px, 85.5%, 1024px);
    overflow-x: hidden;
    display:flex;
    flex-direction:column;
    background:white;
    //box-shadow: 0 0 10px 5px rgba(13,3,15,0.3);
    //border: 1px solid gray;
`
export const Close = styled(animated.div)`
	position:fixed;
	top:0;
	left:0;
  	right:0;
	bottom:0;
	cursor:pointer;
	background:rgba(14,22,38, 0.5);
`
export const PhoneCloseMobile = styled.div`
    position:fixed;
    top:65px;
    right:5px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding:5px;
    cursor:pointer;
`
export const PosCloseBtnBg = styled.div`
    position:absolute;
    border-radius:50%;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.5);
    z-index:-1;
`
export const PrevNextNav = styled.div`
    position:absolute;
    top:45vh;
    ${props => props.l ? 'left:10px' : 'right:10px'};
`