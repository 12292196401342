import { createGlobalStyle }  from "styled-components"

export const GlobalStyles = createGlobalStyle`
    html, body{
        font-family: 'Roboto Slab', serif;
        font-size:1em;
        background:linear-gradient(0deg, rgba(243,243,243,1) 55%, rgba(255,255,255,1) 75%);
        overflow:hidden;
        height:100vh;
        letter-spacing:-0.02rem;
    }
    a {
        text-decoration: none;
    }
    `