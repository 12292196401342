import styled from 'styled-components'

export const HomeCont = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    //justify-content:center;
	overflow-x:hidden;
	overflow-y:auto;
	padding-top:3vw;
    width:100%;
	margin-top:50px;
	height:calc(100vh - 50px);
`
export const SliderCont = styled.div`
    width:100%;
    max-width:1024px;
    height:auto;
    display:flex;
    flex-direction:column-reverse;
`
export const SectionBtns = styled.ul`
    width:100%;
    max-width:1024px;
    height:auto;
    display:flex;
    flex-flow:wrap;
    margin-top:20px;
    //background:rgba(14,22,38,0.1);
    //border-radius:3px 3px 0 0;
`
export const SectionBtn = styled.li`
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:1em;
    color:white;
    padding:6px;
    //border:solid 1px rgba(14,22,38,0.8);
    border-radius:3px 3px 0 0;
    background:${props => props.selection ? 'rgba(14,22,38,0.7)' : 'rgba(14,22,38,0.5)'};
    margin-right:2px;
    cursor:pointer;

    &:hover{
        background:rgba(14,22,38,0.7);
    }
`
export const TxtIconsCont = styled.ul`
    display:flex;
	flex-wrap:wrap;
    //align-items:center;
    justify-content:space-evenly;
    max-width:1500px;
	margin:50px 0;
    color:rgba(0,0,0,1);
`
export const TxtIcon = styled.li`
    max-width:250px;
    margin:25px;
    line-height:1.3;
    text-align:center;
  	background:rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
    border-radius:12px;
    padding:30px;

    p{
        margin-top:15px;
        text-align:left;
        color:rgba(14,22,38,0.8);
    };
`
export const ApiText = styled.div`
    width:100%;
	font-size:1em;
	color:rgba(14,22,38,0.8);
	display:flex;
  	align-items:center;
    margin-left:10px;
`