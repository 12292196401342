import styled, {css, keyframes} from 'styled-components'
import Select from 'react-select'

export const CustomSelect = styled(Select)`
	width:clamp(40vw, 400px, 70vw);
	margin:0 3px;

	& .Select__control--is-focused {
		border:2px solid rgba(255, 221, 85, 1);
	}

	& .Select__control--is-selected {
		border:2px solid rgba(255, 221, 85, 1);
	}

	/*& .Select__input {
		color: blue;

	}

	& .Select__dropdown-indicator {

	}

	& .Select__loading-indicator {
	color: green;
	}

	& .Select__control {
		border:1px solid rgba(255, 221, 85, 1);
	}

	:hover {
	& .Select__clear-indicator {
		visibility: visible;
	}
	}*/
`


  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
export const CenterHV = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-flow:column;
    align-items:center;
    justify-content:center;
    font-size:1.2rem;
`
  export const SearchLoaderCont = styled.div`
  	position:relative;
	width:35px;
	height:35px;
` 
export const SearchLoader = styled.div`
	position:absolute;
	border: 2px solid rgba(255, 204, 0, 0.3);
	border-top: 2px solid rgba(255, 204, 0, 1);
	border-radius: 50%;
	width:100%;
	height:100%;
	animation: ${spin} 1s linear infinite;
` 
export const SearchSubmitBtn = styled.button`
	position:relative;
	width: 40px;
	height: 40px;
    border-radius:50%;
	border: 1px solid rgba(255, 221, 85, 1);
	background:white;
    cursor: pointer;
    color:rgba(14,22,38,1);
	display:flex;
    align-items:center;
    justify-content:center;

    &:hover{
		border: 1px dashed rgba(255, 221, 85, 1);
    }
` 
export const SearchCont = styled.div`
	position:relative;
	display:flex;
    //align-items:center;
    //justify-content:center;
	flex-direction:column;
	width:100%;
`
export const TxtCont = styled.div`
	display:flex;
	//align-items:center;
	//justify-content:center;
	flex-direction:row;
	margin:10px;
`
export const SearchSelectTxt = styled.span`
	display:flex;
	//flex-direction:row;
`
export const SearchSelectBtn = styled.button`
	width: 40px;
	height: 40px;
	display:flex;
	align-items:center;
	justify-content:center;
	border: 1px solid rgba(255, 221, 85, 1);
	border-radius:5px;
	margin-right:3px;
	cursor:pointer;
	background:${props => props.isSearch ? 'rgba(14,22,38,0.7) ': 'white'};
`
export const Form = styled.form`
	display:flex;
    align-items:center;
`
export const BtnText = styled.div`
	margin:5px;
	font-size:1em;
`
export const BtnLabel = styled.label`
	color:white;
`
export const Label = styled.label`
	display:flex;
    //align-items:center;
    //justify-content:center;
	flex-direction:column;
`
const basic = css`
	border: 1px solid rgba(255, 221, 85, 1);
	border-radius:50px;
	margin: 6px;
	padding: 10px;
	font-family: 'Roboto Slab', serif;
	font-size:1.1em;

	&:focus{
		border: 2px solid rgba(255, 221, 85, 1);


		::placeholder{
			color:white;
		}
    }
`
/*export const Select = styled.select.attrs(props => ({
	type: "select"
}))`
	${basic}
	width:clamp(40vw, 400px, 70vw);
	color:rgba(14,22,38,1);
	background:white;
`*/
export const Search = styled.input`
	color:rgba(14,22,38,1);
	width:clamp(35vw, 160px, 70vw);
	border: 1px solid rgba(255, 221, 85, 1);
	border-radius:5px;
	margin: 0 3px;
	padding: 8px;
	background:white;

	&:focus{
		border: 3px solid rgba(255, 221, 85, 1);

		::placeholder{
			//color:white;
		}
	}
`
export const PasswordReset = styled(Search)`
	width:clamp(35vw, 250px, 70vw);
`

export const DataList = styled.datalist``
export const Option = styled.option``

export const ApiEventText = styled.div`
	width:100%;
	margin:15px;
	font-size:1em;
    background:rgba(255, 230, 128, 1);
    padding:15px;
    text-align:center;
`
export const Text = styled.input.attrs(props => ({
	type: "text"
}))`
    ${basic}
  `
export const Url = styled.input.attrs({
	type: "url",
})` 
    ${basic}
  `
export const Email = styled.input.attrs({
	type: "email",
})` 
    ${basic}
  `
export const Password = styled.input.attrs({
	type: "password",
})` 
    ${basic}
  `
export const Number = styled.input.attrs({
	type: "number",
	step:"any"
})` 
    ${basic}
  `