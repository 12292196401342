import * as Realm from "realm-web"

const config = {
	id:"joyrealm-pvkua",
	timeout: 10000 // timeout in number of milliseconds
}

const app = new Realm.App(config)

function mongo(){
	let atlas = app.currentUser.mongoClient("mongodb-atlas")
	return atlas.db("joy_db")
}

export async function funcSignIn(user){

	return await user.callFunction("userAdmin", user.id)
}

export async function userAnon() {
	// Create an anonymous credential
	const credentials = Realm.Credentials.anonymous()
	try {
		// Authenticate the user
		const user = await app.logIn(credentials)
		return {mongo:mongo(), app, user}

	} catch (err) {
		console.error("Failed to log in", err)
	}
}

export async function userSignIn({email, password}) {

	const credentials = Realm.Credentials.emailPassword(
		email,
		password
	)
	try {
		const user = await app.logIn(credentials);

		return {mongo:mongo(), user, app}
	} catch (err) {
		console.error("Failed to log in", err.message)
		return false
	}
}

export async function userSignUp({email, password}) {

	let auth = await app.emailPasswordAuth.registerUser(email, password)
	if(auth) {
		return true
	}
	else {
		console.error("Failed to Sign Up")
		return false
	}

}

