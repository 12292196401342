import {useState, useEffect, useCallback} from 'react'
import styled from 'styled-components'
import { FiX } from 'react-icons/fi'

const MainCont = styled.div`
    width:100%;
    color:rgba(14,22,38, 1);
    background:linear-gradient(0deg, rgba(78, 154, 6, 0.1) 55%, white 75%);

    .header{
        position:relative;
        background:rgba(78, 154, 6, 0.7);
        font-size:1.8rem;
        padding:35px 30px 10px;
        //margin:0 0 15px;
        color:white;

        &::after{
            content:'';
            position:absolute;
            bottom:-15px;
            left:0;
            right:0;
            border-top: 15px solid rgba(78, 154, 6, 0.7);
            border-right: 30vw solid transparent;
            //filter:drop-shadow(-1px 4px 2px rgba(0,0,0,0.2));
        }

        .aside{
            position:absolute;
            top:20px;
            right:20px;
            display:flex;
            justify-content:center;
            align-items:center;
            border-radius:50%;
            background:rgba(78, 154, 6, 0.3);
            padding:8px;
            cursor:pointer;

            &:hover{
                background:rgba(78, 154, 6, 0.5);
            }
        }
    }

    .main{
        display:flex;
        flex-direction:column;
        justify-content:center;
        width:100%;
        margin-top:35px;

        .section{
            flex-grow:1;
            padding:0 30px;

            .h4{
                margin:10px 0 30px;
            }

            .h3{
                margin-bottom:15px;
            }

            .article{
                background:white;
                padding:25px;
                margin:15px 0;
                border-radius:12px;
                border:1px solid rgba(78, 154, 6, 0.5);

                li{
                    width:100%;
                    margin-bottom:10px;
                    &::first-line{

                        text-transform: uppercase;
                        font-size: 0.8rem;
                        color:rgba(14,22,38,0.8);
                    }
                }
            }
        }
        
    }
    .footer{
        font-size:0.7rem;
        padding:10px;
        background:rgba(14,22,38, 0.8);
        color:white;
    }
`

export default function PurchaseHistory({setPurchHist, verified, mongo}){

    const [purchases, setPurchases] = useState([])

    let close = e => {
        e.preventDefault()
        setPurchHist(false)
    }

    const getTransactions = useCallback(() => {

        verified.email && mongo.collection('transactions')
        .find({"email": verified.email })
        .then(result => {

            if(result) {

                setPurchases(result.map(p => {

                    const {id, purchase_units:[{
                        reference_id, 
                        amount:{
                            value,
                            currency_code
                        },
                        payee:{
                            email_address,
                            merchant_id
                        },
                        items
                    }],
                    payer:{
                        name:{
                            given_name, 
                            surname
                        }, 
                        //email_address, 
                        //payer_id, 
                        //address:{country_code}
                    },
                    create_time
                } = p.order

                return {
                    id,
                    reference_id,
                    item_ids:items.map(itm => (itm.description)),
                    itemRefs:items.map(itm => (itm.sku)),
                    value,
                    currency_code,
                    contact:email_address,
                    merchant_id, 
                    given_name,
                    surname, 
                    create_time
                }

                }))
            } else {
                console.log("No document matches the provided query.")
            }
        })
        .catch(console.log("Error getting purchases."))

    }, [mongo, verified.email])
    
    useEffect(() => { getTransactions() }, [getTransactions])

    return <MainCont>
        <header className={'header'}>
            Purchase History
            <aside className={'aside'} onClick={close}><FiX size={24} color={'white'} /></aside>
        </header>
        <main className={'main'}>
            <section className={'section'}>
            {
                purchases.length === 0
                ?   <h4 className={'h4'}>You have not made any purcheses yet.</h4>
                :   <>
                        <h3 className={'h3'}>{purchases[0].given_name} you have made "{purchases.length}" purcheses.</h3>
                        {purchases.map((purch,i) => {

                            const {
                                id,
                                //reference_id,
                                //item_ids,
                                itemRefs,
                                value,
                                currency_code,
                                contact,
                                merchant_id, 
                                given_name,
                                surname, 
                                create_time
                            } = purch

                            return( 
                                <article key={'purch'+i} className={'article'}>
                                    <ul>
                                        <li>Name:<br/> {`${given_name} ${surname}`}</li>
                                        <li>Date and time of purchase:<br/> {new Date(create_time).toString()}</li>
                                        <li>Number of Items Purchased:<br/> {itemRefs.length}</li>
                                        <li>Purchase References:<br/> {itemRefs.map(itm => (`${itm} `))}</li>
                                        <li>PayPal order-Id:<br/> {id}</li>
                                        <li>Total:<br/> {`${value} ${currency_code}`}</li>
                                        <li>---------------</li>
                                        <li>Rinchen Minerals</li>
                                        <li>Email:<br/> {contact}</li>
                                        <li>PayPal Merchant Id:<br/> {merchant_id}</li>
                                    </ul>
                                </article>                        
                                )
                            })
                        }
                    </>
                }
            </section>
        </main>
        <footer className={'footer'}>Rinchen Minerals 2021</footer>
    </MainCont>
}