import {useEffect, useCallback} from 'react'
import {useSpring} from '@react-spring/web'
import {OverlayCont, Overflow, Close, PhoneCloseMobile, PosCloseBtnBg } from './overlayStyles'
import { FiX } from 'react-icons/fi'
 
export default function Overlay ({
    open, 
    setOpen,
    onClose,
    children
}){

  	const [closeSpring, setCloseSpring] = useSpring(() =>({opacity:'0'}))
  	const [springOpenClose, setSpringOpenClose] = useSpring(() => ({opacity:'0', transform:'scale(0.9) translate3d(0px,500px,0px)'}))

    const close = useCallback(e => {
        e && e.preventDefault() 
		setCloseSpring.start({opacity:'0'})
        setSpringOpenClose.start({opacity:'0', transform:'scale(0.9) translate3d(0px,500px,0px)', 
        onRest: () =>  {
			onClose && onClose()
			setOpen(false)
        }, 
        config:{ mass: 1, tension: 500, friction: 40 }})

    }, [onClose, setOpen, setSpringOpenClose, setCloseSpring])

    /*useEffect(() => {
        console.log('closed', open)
        //!open && close()
    }, [open, close])*/

    useEffect(() => {
		setCloseSpring.start({opacity:'1'})
		setSpringOpenClose.start({
			opacity:'1', 
			transform:'scale(1) translate3d(0px,0px,0px)', 
			config:{ mass: 1, tension: 500, friction: 40 }
		})
  	}, [open, setSpringOpenClose, setCloseSpring])
        
    return <OverlayCont>
            <Close style={closeSpring} onClick={close} />
            <Overflow style={springOpenClose}>
                {children}
            </Overflow>                
            <PhoneCloseMobile onClick={close} ><PosCloseBtnBg /><FiX size={24} color={'black'} /></PhoneCloseMobile>                
        </OverlayCont>
}



