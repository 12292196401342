import {useState, useEffect} from "react"
import {CenterHV, Form, PasswordReset, SearchSubmitBtn, SearchLoader, ApiEventText} from './formStyles'
import Overlay from '../overlay/Overlay'
import { FiThumbsUp, FiSend } from 'react-icons/fi'

export default function Reset({
    apiEvent,
    setApiEvent,
    mongoApp, 
    useLocation, 
    useHistory
}){
    const [openConf, setOpenConf] = useState(true)
    const [changed, setChanged] = useState('')
    const [hasChanged, setHasChanged] = useState(false)
	const history = useHistory()
    const {search} = useLocation()
    const searchParams = new URLSearchParams(search)

    const token = searchParams.get('token')
    const tokenId = searchParams.get('tokenId')

    const passChangeCheck = e => {
        e.preventDefault()
        setApiEvent(state => ({...state, loading:true}))
        
        let passChange = async () => {

            await mongoApp.emailPasswordAuth.resetPassword(token, tokenId, changed)
            
            setApiEvent(state => ({...state, text:'You can now sign-in with your new password.', loading:false}))
            setHasChanged(true)
            setTimeout(() => {
                setOpenConf(false)
                history.push('/')
            }, 3000)
        }
        passChange()
    }

    useEffect(() => {
        setApiEvent(state => ({...state, name:'reset', loading:false, text:'Please enter a new password.'}))
    }, [setApiEvent])

    return <Overlay
        open={openConf}
        setOpen={setOpenConf}
        bgColor={'linear-gradient(-5deg, rgba(209,209,209,0.9) 25%, rgba(255,255,255,1) 100%)'}
        iconColor={'#fff'}
        >
            <CenterHV>
                {!hasChanged
                    ?   <Form onSubmit={passChangeCheck}>
                            <PasswordReset
                            type='password'
                            name='reset'
                            minlength="6"
                            maxlength="50"
                            placeholder={`Enter a new password.`}
                            onChange={e => setChanged(e.target.value)} 
                            value={changed}
                            autoFocus
                            />
                            <SearchSubmitBtn type={'submit'}>
                                {apiEvent.loading && apiEvent.name === 'reset' && <SearchLoader />}
                                <FiSend color={'rgba(14,22,38,0.5)'} size={24} />
                            </SearchSubmitBtn>
                        </Form>
                    :   <CenterHV>
                            <FiThumbsUp color={'green'} size={120} />
                            <br/>
                            <p>Password Reset! You can now sign-in with your new password.</p>
                        </CenterHV>
                }
                {
                    <ApiEventText>
                        {apiEvent.name === 'reset' ? apiEvent.text : 'Please enter a new password.'}
                    </ApiEventText> 
                }
            </CenterHV>
        </Overlay>

}