import {useState, useEffect, useCallback, useRef} from "react"
import {PayPalScriptProvider} from "@paypal/react-paypal-js"
import {
    BrowserRouter as Router
} from "react-router-dom"
import styled, {keyframes} from 'styled-components'
import {userAnon} from './components/realm/realm'
import {GlobalStyles} from './components/Styles'
import Main from './components/Main'

const spin = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`
export const LoaderCont = styled.div`
  	position:relative;
	width:100%;
	height:calc(100vh - 50px);
	display:flex;
	align-items:center;
	justify-content:center;
` 
export const Loader = styled.div`
	border: 10px solid rgba(255, 204, 0, 0.3);
	border-top: 10px solid rgba(255, 204, 0, 1);
	border-radius: 50%;
	width:100px;
	height:100px;
	animation: ${spin} 1s linear infinite;
` 

function App() {
	
	const [mongo, setMongo] = useState({})
	const [mongoApp, setMongoApp] = useState({})
	const [mongoUser, setMongoUser] = useState({})
	const [itemColls, setItemColls] = useState({})

	const once = useRef(true)
	const cnt = useRef(0)
    const getItemsRef = useRef({names:[]})
    const getItems = getItemsRef.current

	const PayPalIntOptions = {
		// my sandbx"client-id": "AVHNeX-1tjevx-16xfa6Et2W-G33J81yZdMMcV9Lm9hp14sOOh3CZWEefEeQ8Ez5dM1w7_wYaYTXlEAv",
		//rm sandbx"client-id": "AZebxQdbl6W8oZzUhG3t5LPrQxwHnZS4Yqav2WDonGxPlL6eqSFmWTjCiezN0ugT8DGav6M2_AOtrbQo",
		"client-id": "AY9knNJK-DQNPCLp9VwBegPh0ECGJMB86CTpQ85zvqZOeIPM-W9-17P_slg3ef32KIqA6-I9sDrfiZz7",
		currency: "GBP"
	}
    
    let findCollCallback = useCallback((success, result, db, indx) => {

        if(success){
            getItems[getItems.names[indx] + 'Itms'] = result

			if(cnt.current === getItems.names.length-1){
				setItemColls(getItems)
				setMongo(db.mongo)
				setMongoApp(db.app)
				setMongoUser(db.user)				
			}else{
				cnt.current++
			}
        }
		else console.log(success, result)
    }, [getItems])

	let findIdCallback = useCallback((success, result, db) => {	

		if(success){
			getItems.names = result.map(n => (n.name))

            result.forEach((res,i) => {
                getItems[res.name] = res.list

                db.mongo.collection('items').find({"_id": {$in:res.list}})
                .then(result => findCollCallback(true, result, db, i))
                .catch(err => findCollCallback(false, err))
            })
        }else console.log(success, result,)

    }, [findCollCallback, getItems])

	useEffect(() => {
		if(once.current){
			once.current = false
			userAnon().then( db => {

				db.mongo.collection('item_collections').find({})
				.then(result => findIdCallback(true, result, db))
				.catch(err => findIdCallback(false, err))

			})
		}
	}, [findIdCallback])

  return (
    <div className="App">
      	<GlobalStyles />
		<PayPalScriptProvider options={PayPalIntOptions}>
			{
			itemColls.ourFavsItms
				? 	<Router>  
						<Main {...{
							mongo, 
							setMongo, 
							mongoApp,
							setMongoApp,
							mongoUser, 
							setMongoUser,
							itemColls, 
							setItemColls
						}} />
					</Router>				
				: 	<LoaderCont>
						<Loader />
					</LoaderCont>
			}
		</PayPalScriptProvider>
    </div>
  )
}

export default App