import {useState, useRef} from 'react'
import {updateOne} from '../realm/insertUpdateFind'
import {TopCont, BotCont, Text, BtnGrp, Form, Title, Ul, Li, SubmitBtns, SubmitLoader, BtnTxt} from './editDataListStyles'
import useForm from './useForm'
import {dataListForm} from './forms'

export default function EditDataList ({
    dataList, 
    setDataList,
    apiEvent,
    setApiEvent,
    mongo
}){
    const [action, setAction ] = useState('')

    let selection = useRef({})
    let newEntry = useRef('')

    let reset = () => Object.keys(state).map(key => (changeHandler({target:{value:'', id:key}})))

    let successCallback = (success, result) => {

        setApiEvent(state => ({...state, loading:false}))

        if(success){
            if(action === 'up' || action === 'down'){

                let indx = action === 'up' 
                ? selection.current.indx 
                : selection.current.indx+1

                setDataList(state => {
                    let s = state.slice(0)
                    s.splice(indx, 0, newEntry.current)
                    return s
                })

                setApiEvent(state => ({...state, text:`Successfully inserted tag`}))

            }
            else if(action === 'update' || action === 'delete'){
                setDataList(state => {
                    let s = state.slice(0)
                    action === 'update'
                    ? s.splice(selection.current.indx, 1, newEntry.current)
                    : s.splice(selection.current.indx, 1)

                    return s
                })
            }

            selection.current = {}
            reset()

        }else{
            setApiEvent(state => ({...state, text:`Failed to insert tag. Try again.`}))
        }
            
        setTimeout(() => {setApiEvent(state => ({...state, text:'Add another one or close.'}))}, 5000)
    }

    const { state, submitHandler, changeHandler } = useForm(dataListForm, values => {

        if(!values.entry || 
        selection.current.indx === undefined ||
        (selection.current.value === values.entry && action !== 'delete') 
        ) return
        
        let indx = selection.current.indx
        let oldVal = selection.current.value
        //selection.current.indx = undefined

        setApiEvent(state => ({...state, name:action, loading:true}))
        newEntry.current = values.entry

        if(action === 'up' || action === 'down'){

            let insertValAt = {
                "$push": {
                    "list": {
                       "$each": [values.entry],
                       "$position": action === 'up' 
                        ? indx
                        : indx +1
                    }
                 }
            }

            updateOne(mongo, 'data_lists',{name:'main'}, insertValAt, successCallback )
        }
        else if(action === 'update' || action === 'delete'){ 

            let updateValAt = action === 'update' 
            ? {"$set": { 'list.$': values.entry}}
            :{"$pull": { 'list': oldVal}  }

            updateOne(mongo, 'data_lists', {"list":oldVal}, updateValAt , successCallback )
        }
    })

    let selected = (v,i) => e => {
        e.preventDefault()
        changeHandler({target:{value:v, id:'entry'}})
        selection.current = {indx:i, value:v}
    }

    let actionSet = act => e => {
        setAction(act)
    }

    return <>
        <TopCont><Title>Edit Data List</Title></TopCont>
        <BotCont>
                <Form onSubmit={submitHandler}>
                    {Object.keys(state).map(key => (
                        (state[key].type === 'dl0' &&
                            <Text
                            key={key}
                            name ="dl0"
                            minlength="3"
                            maxlength="50"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />)
                        ))}
                            <BtnGrp>
                            <SubmitBtns onClick={actionSet('up')} type={'submit'}>
                                {apiEvent.loading && apiEvent.name === 'up' ? <SubmitLoader /> : <BtnTxt>Above</BtnTxt>}
                                
                            </SubmitBtns>
                            <SubmitBtns onClick={actionSet('down')} type={'submit'}>
                                {apiEvent.loading && apiEvent.name === 'down' ? <SubmitLoader /> : <BtnTxt>Below</BtnTxt>}                                
                            </SubmitBtns>
                            <SubmitBtns onClick={actionSet('update')} type={'submit'}>
                                {apiEvent.loading && apiEvent.name === 'update' ? <SubmitLoader /> : <BtnTxt>Update</BtnTxt>}                                
                            </SubmitBtns>
                            <SubmitBtns onClick={actionSet('delete')} type={'submit'}>
                                {apiEvent.loading && apiEvent.name === 'delete' ? <SubmitLoader /> : <BtnTxt>Delete</BtnTxt>}                               
                            </SubmitBtns>
                        </BtnGrp>
                    </Form>
            <Ul>
                {dataList.map((v,i) => (
                    <Li key={`dl${i}`} onClick={selected(v,i)}>{v}</Li>
                ))}
            </Ul>
        </BotCont>
    </>
}