export const signIn = {
    email: {
        type:'email',
        label: 'Email',
        value: '',
    },
    password: {
        type:'password',
        label: 'Password',
        value: '',
    },
}
export const userSignUpForm = {
    username: {
        type:'text',
        label: 'Username',
        value: '',
    },
    password: {
        type:'password',
        label: 'Password',
        value: '',
    },
}
export const userSignInForm = {
    username: {
        type:'text',
        label: 'Username',
        value: '',
    },
    password: {
        type:'password',
        label: 'Password',
        value: '',
    },
}
export const select = {
    select: {
        type:'select',
        label: 'Select',
        value: '',
    }
}
export const search = {
    search: {
        type:'search',
        label: 'Search',
        value: '',
    }
}
export const dataListForm = {
    entry: {
        type:'dl0',
        label: 'Select tag to edit',
        value: '',
    }
}
export function adminItem(vals) {
    //starts at one, id takes [0]
    return {
    tags: {
        type:'t1',
        label: 'Enter tags',
        value: vals ? vals[1] : '',
    },
    reference: {
        type:'t2',
        label: 'Enter a reference',
        value: vals ? vals[2] : '',
    },
    name: {
        type:'t3',
        label: 'Enter a name',
        value: vals ? vals[3] : '',
    },
    location: {
        type:'t4',
        label: 'Enter a location',
        value: vals ? vals[4] : '',
    },
    classification: {
        type:'t5',
        label: 'Enter a classification',
        value: vals ? vals[5] : '',
    },
    measurements: {
        type:'t6',
        label: 'Enter measurements',
        value: vals ? vals[6] : '',
    },
    points: {
        type:'t7',
        label: 'Enter points of interest',
        value: vals ? vals[7] : '',
    },
    image: {
        type:'t8',
        label: 'Enter image publicIds',
        value: vals ? vals[8] : '',
    },
    title: {
        type:'t9',
        label: 'Enter a title',
        value: vals ? vals[9] : '',
    },
    desc: {
        type:'t10',
        label: 'Enter a description',
        value: vals ? vals[10] : '',
    },
    price: {
        type:'n1',
        label: 'Enter a price',
        value: vals ? Number(vals[11]): 0,
    }}
}