import { useState, useRef, useEffect, useCallback } from "react"
import {
    useFullScreenClose, 
    Close, 
    CartCont, 
    CartTitle, 
    CartItems, 
    CartItem, 
    Thumb, Title, Price, Remove, DetailsCont, PriceFinal, CheckoutCont} from './cartStyles'
import {Image, Transformation} from 'cloudinary-react'
import UserCont from '../users/UserCont'
import Checkout from '../payPal/Checkout'
import Approved from '../payPal/Approved'
import PurchaseHistory from '../users/PurchaseHistory'
import { FiX, FiShoppingCart } from 'react-icons/fi'
//import useFullScreenClose, {Close} from '../useFullScreenClose'

export default function Cart({
    cart, 
    setCart, 
    setOpenCart, 
    openCart, 
    cartSpring, 
    setCartHeight, 
    setIsItemInCart,
    verified,
    setVerified,
    mongo,
    mongoUser, 
    openVerified,
    favItems,
    setFavItems,
    favItemIndx,
    setFavItemIndx,
    setOpenOverlayA,
    purchHist, 
    setPurchHist,
    setItems
}) {

    const getHightRef = useRef()
    const [totalPrice, setTotalPrice] = useState(0)
    const [checkout, setCheckout] = useState({})
    const checkoutOnce = useRef(false)
    
    const {closeSpring, setCloseSpring} = useFullScreenClose()

    const afterPurchase = useCallback( async () => {
        
        if(checkout.id){

            let item_Ids = cart.map(itm => (itm._id.toString()))
            let success = await mongoUser.functions.soldAuth({orderId:checkout.id, _ids:item_Ids})
            
            if(success.success){
                
                setCart([])
                setIsItemInCart([])
            
                success.verifiedUser && setVerified(state => (
                    {   ...state, 
                        purchased:[...state.purchased, success.newTransactionId]
                    }))
                    
                setItems(state => {
                    let itms = state.slice()
                    return itms.map(itm => (
                        item_Ids.some(id => id === itm._id.toString()) 
                        ? {...itm, price:0} 
                        : itm
                        ))
                })

                checkoutOnce.current = false
            }
        }
        else console.log('Failed to retun. Something went wrong in the func call.')

    }, [cart, checkout.id, mongoUser, setCart, setVerified, setItems, setIsItemInCart])

    useEffect(() => {
        let h = getHightRef.current.getBoundingClientRect().height
        setCartHeight(Math.round(h))
        setTotalPrice(
            cart
            .map(item => (Number(item.price)))
            .reduce((sum,value) => (sum + value), 0)
        )
    }, [cart, checkout, setCartHeight, openVerified, openCart])

    useEffect(() => {
        openCart
            ? setCloseSpring.start({visibility:'visible', opacity:'1', onRest:() => null})
            : setCloseSpring.start({opacity:'0', onRest:() => setCloseSpring.start({visibility:'hidden'})})
    }, [openCart, setCloseSpring])

    useEffect(() => {
        
        if(!checkoutOnce.current && checkout.payee){
            afterPurchase()
            checkoutOnce.current = true
        }
    }, [checkout, afterPurchase])

    return <>                 
        <Close 
        style={closeSpring} 
        onClick={e => {
		    e.preventDefault()
            setOpenCart(false)
            }
        } 
        />
        <CartCont ref={getHightRef} style={cartSpring}>
            {!purchHist 
                ?   !checkout.payer    
                    ?   <>
                            <CartTitle><FiShoppingCart color={'rgba(78, 154, 6, 0.9)'} size={35} style={{marginRight:'10px'}} />
                            {`${verified.customData ? verified.customData.name : 'User'}, your cart has "${cart.length}" items.`}
                            </CartTitle>
                            <CartItems>
                                {cart.map((item, i) => (
                                    <CartItem key={`ca${i}`}>
                                        <Thumb>
                                            <Image cloud_name='rinchen' publicId={item.image[0]}>
                                                <Transformation effect="sharpen:70" width="100" crop="scale" />
                                            </Image>
                                        </Thumb>
                                        <Title>{item.title} - {item.reference}</Title>
                                        <Price>£ {item.price}</Price>
                                        <Remove onClick={e => {
                                            e.preventDefault()
                                            setCart(state => state.filter(v => v.reference !== item.reference))
                                            setIsItemInCart(state => state.filter(v => v !== item.reference))
                                            }}>
                                                <FiX color={'black'} size={24} />
                                        </Remove>
                                    </CartItem> 
                                ))}
                            </CartItems>
                            <DetailsCont>
                                <PriceFinal> Your current subtotal: £ {totalPrice}</PriceFinal>
                            </DetailsCont>
                            <CheckoutCont>
                                {cart.length > 0 ? <Checkout {...{cart, setCheckout, verified}}  /> : ''}
                            </CheckoutCont>
                            {openVerified &&
                                <UserCont {...{
                                    verified,
                                    favItems,
                                    setFavItems,
                                    setVerified,
                                    favItemIndx,
                                    setFavItemIndx,
                                    setOpenOverlayA,
                                    mongo
                                    }}
                                />
                            }
                        </>
                    :   <Approved {...{setCheckout, checkout, setCart}}/>
                            
                : <PurchaseHistory {...{setPurchHist, verified, mongo}}/>
            }
        </CartCont>
    </>
}