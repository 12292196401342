import styled from 'styled-components'

export const MainCont = styled.div`
    width:100%;
    position:relative;
    display:flex;
    background:white;
    padding:25px clamp(15px, 2vw, 50px) 150px ;
    color:rgba(14,22,38,0.7);
    margin-top:50px;

    &::after{
        content:'';
        position:absolute;
        top:-25px;
        left:0;
        right:0;
        //width: 0;
        //height: 0;
        border-bottom:25px solid rgba(255,255,255,1);
        border-left: 60vw solid transparent;
        //filter:drop-shadow(-1px 4px 2px rgba(0,0,0,0.2));
    }

    span{
        display:inline-flex;
        align-items:center ;
        margin:0 clamp(5px, 2vw, 10px);
        cursor:pointer;

        &:hover{
            color:rgba(14,22,38,1);
        }
    }

    p{
        position:absolute;
        bottom:25px;
        right:30px;
        color:rgba(14,22,38,0.5);
    }
`
