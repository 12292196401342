import styled, {keyframes} from 'styled-components'
import {a} from '@react-spring/web'

const spin = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`
export const OverflowCont = styled.div`
    position:relative;
	width:100%;
    min-height:150px;
    display:flex;
    align-items:center;
    overflow-x:hidden;
    background:rgba(0,0,0,1);
`
export const SliderCont = styled(a.div)`
    position:relative;
	width:100%;
    //height:clamp(100px, 75vh, 1200px);
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:${props => props.urlon === 'true' ? 'pointer' : 'arrow'};
`
export const DefineHeight = styled.div`
    opacity:0;
	width:100%;
`
export const LeftSlideCont = styled.div`
    position:absolute;
    top:0;
    left:${({leftRight}) => leftRight ? '100%' : '-100%'};
	width:100%;
	height:100%;
`
export const MidSlideCont = styled.div`
    position:absolute;
    top:0;
    left:0%;
	width:100%;
	height:100%;
`
export const RightSlideCont = styled.div`
    position:absolute;
    top:0;
    left:100%;
	width:100%;
	height:100%;
`
export const LoaderCont = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    align-items:center;
    justify-content:center;
    background:rgba(0,0,0,0.7);
`
export const Loader = styled.div`
	border: 7px solid rgba(255, 204, 0, 0.3);
	border-top: 7px solid rgba(255, 204, 0, 1);
	border-radius: 50%;
	width:75px;
	height:75px;
	animation: ${spin} 1s linear infinite;
` 
export const ThumbsCont = styled.div`
	width:100%;
    padding-top:2px;
    background:rgba(0,0,0,1);
    display:flex;
    align-items:flex-end;
    overflow-x:auto;
    //cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    //auto;
`
export const Thumb = styled.div`
    margin:0 2px;
    cursor:pointer;
    border-bottom:5px solid ${props => props.selected ?  'rgba(255, 204, 0, 1)' : 'rgba(0,0,0, 1)'};
    opacity:${props => props.selected ?  1 : 0.5};

    &:hover{
        opacity:1;
    }
`
export const SlideLeftBtn = styled.div`
    position:absolute;
    display:flex;
    align-items:center;
    justify-content:center;
    width:clamp(45px, 10vw, 65px);
    height:clamp(45px, 10vw, 65px);
    background: ${props => props.startEnd ?  'rgba(0,0,0, 0.4)': 'rgba(0,0,0, 0.2)'};
    ${props => props.startEnd && 'cursor:pointer;'}
    
    border-radius:50%;
    margin:15px;
    opacity:${props => props.startEnd ?  1 : 0.5};


    &:hover{
        background: ${props => props.startEnd ?  'rgba(0,0,0, 0.5)': 'rgba(0,0,0, 0.2)'};
    }
`
export const SlideRightBtn = styled(SlideLeftBtn)`
    right:0;
`