import styled, {keyframes} from 'styled-components'
  
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`
  export const SpinLoader = styled.div`
  	position:absolute;
	border: 2px solid rgba(255, 221, 85, 1);
	border-top: 2px solid rgba(14,22,38,1);
	border-radius: 50%;
	width: 90%;
	height: 90%;
	animation: ${spin} 1s linear infinite;
` 

export const BtnCircle = styled.span`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:center;
    width:35px;
    height:35px;
    background:${props => props.bg ? props.bg :' rgba(255, 221, 85, 1))'};
    border-radius:3px;
    user-select: none;
` 
export const Btn = styled.button`
    border-radius:3px;
    background:${props => props.bg ? props.bg :'rgba(255, 221, 85, 1)'};
    display: flex;
    align-items:center;
    justify-content:space-around;
    margin:5px 10px;
    box-shadow: 1px 1px 3px black;
    cursor: pointer;
    opacity:${props => props.opa ? 0.3 : 1};
    max-width:${props => props.maxW || 300}px;
    color:${props => props.color ? props.color :'black'};

    &:hover{
        background:${props => props.bg};
        opacity:0.9;
    }
` 
export const BtnText = styled.span`
    padding: 5px 10px 5px 5px;
    font-size:0.9rem;
    font-family:Arial, Helvetica, sans-serif;
    user-select: none;
` 
export default function DefaultBtn({
    maxW, 
    disabled, 
    click, 
    bg, 
    color, 
    icon, 
    text, 
    loading
}){

    return (
        <Btn 
        maxW={maxW} 
        opa={disabled} 
        disabled={disabled} 
        onClick={click}
        bg={bg}
        color={color}
        >
            <BtnCircle bg={bg}>
                {loading && <SpinLoader />}
                {icon}
            </BtnCircle>
            <BtnText>
                {text}
            </BtnText>
        </Btn>
    )
}



