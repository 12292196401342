import {useState, useEffect} from "react"
import Overlay from '../overlay/Overlay'
import { FiThumbsUp } from 'react-icons/fi'
import styled from "styled-components"

export const CenterHV = styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-flow:column;
    align-items:center;
    justify-content:center;
    font-size:1.4rem;
    background:white;
`

export default function Confirmation({mongoApp, useLocation, useHistory, setOpenSignIn, setIsSignIn}){
    const [openConf, setOpenConf] = useState(true)
	const history = useHistory()
    const {search} = useLocation()
    const searchParams = new URLSearchParams(search)

    const token = searchParams.get('token')
    const tokenId = searchParams.get('tokenId')

    //await mongoApp.emailPasswordAuth.resendConfirmation(email)

    const signIn = e => {
        e.preventDefault()
        history.push('/')
        setOpenConf(false)
        setOpenSignIn(true)
        setIsSignIn(true)
    }

    useEffect(() => {

        const emailCheck = async () => {
            await mongoApp.emailPasswordAuth.confirmUser(token, tokenId)
        }
        mongoApp.emailPasswordAuth && emailCheck()
        
	}, [mongoApp, history, setOpenSignIn, token, tokenId])

    return <Overlay
        open={openConf}
        setOpen={setOpenConf}
        bgColor={'linear-gradient(-5deg, rgba(209,209,209,0.9) 25%, rgba(255,255,255,1) 100%)'}
        iconColor={'#fff'}
        >
            <CenterHV onClick={signIn}>
                <FiThumbsUp color={'rgba(78, 154, 6, 0.7)'} size={120} />
                <br/>
                <p>Thanks! You are verified and can now "Sign In".</p>
                <p>Click to continue!</p>
            </CenterHV>
        
        </Overlay>

}