import styled from 'styled-components'

export const MainCont = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:calc(100vh - 50px);
    margin-top:50px;
    overflow-y:auto;
    color:rgba(14,22,38,1);

    .header{
        font-size:2.5rem;
        margin-top:50px;
        padding:30px 50px;
    }

    .icons{
        flex-grow:1;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-flow:wrap;
        justify-content:space-evenly;
        min-width:250px;
        
        >*{
            margin:10px;
        }
    }

    .main{
        flex-grow:1;
        width:clamp(300px, 80%, 1000px);
        padding:30px 0;
    }

    .aside{
        display:flex;
        padding:15px;
        background:rgba(14,22,38,0.7);
        color:white;
        border-radius:12px;
        margin-bottom:30px;
        //width:100%;

        ul{
            li{
                font-size:1.3rem;
                padding:10px;
                cursor:pointer;
            }
        }
    }

    .article{
        header{
            font-size:2rem;
            margin-bottom:30px;
        }
        p{
            font-size:1.1rem;
            line-height:1.5;
            margin-bottom:30px;

            a > span{
                display:flex;
                align-items:center;
                justify-content:center;
                font-size:1.3rem;
                background:rgba(14,22,38,0.9);
                color:white;
                padding:5px;
                border:3px solid rgba(14,22,38,0.1);
                border-radius:12px;

                &:hover{
                    background:rgba(14,22,38,1);

                }
            }
        }
        span{
            display:flex;
            align-items:center;
            width:100%;
            padding:0 10px;
            margin-bottom:15px;
            background:rgba(14,22,38,0.7);
            border-radius:5px;
            color:white;
            
            b{
                flex-grow:1;
                text-align:right;
                cursor:pointer;
            }
        }
    }
`
