import { useState, useEffect } from "react"
import { Route, Switch, useHistory, useLocation } from "react-router-dom"
import { useSpring } from '@react-spring/web'
import Navbar from './navbar/Navbar'
import NavSide from './navSide/NavSide'
import { MainCont, PosBtn, PosBtnBg } from './mainStyles'
import Home from './home/Home'
import About from './about/About'
import Support from './support/Support'
import Privacy from './privacy/Privacy'
import ItemsCont from './item/ItemsCont'
import Cart from './cart/Cart'
import SignIn from './forms/SignIn'
import Overlay from './overlay/Overlay'
import Confirmation from './realm/Confirmation'
import Reset from './forms/Reset'
import { FiLock, FiUnlock } from 'react-icons/fi'
//import minerals from './forms/dList'

export default function Main({
    mongo,
    setMongo,
    mongoApp,
    setMongoApp,
    mongoUser, 
    setMongoUser,
    itemColls, 
	setItemColls
}) {

    const [admin, setAdmin] = useState(false)
    const [openVerified, setOpenVerified] = useState(false)
    const [verified, setVerified] = useState({})
    const [items, setItems] = useState([])
    const [cart, setCart] = useState([])
    const [openCart, setOpenCart] = useState(false)
    const [cartHeight, setCartHeight] = useState(0)
    const [isItemInCart, setIsItemInCart] = useState([])
    const [sideNavOpen, setSideNavOpen] = useState(false)
    const [purchHist, setPurchHist] = useState(false)
    const [apiEvent, setApiEvent] = useState({})
    const [dataList, setDataList] = useState([])
    const [btnMin, setBtnMin] = useState(false)
    const [disableAdminBtn, setDisableAdminBtn] = useState(false)
    const [openSignIn, setOpenSignIn] = useState(false)
    const [isSignIn, setIsSignIn] = useState(false)
    const [openOverlayA, setOpenOverlayA] = useState(false)
    const [favItems, setFavItems] = useState([])
    const [favItemIndx, setFavItemIndx] = useState({ active: false, indx: 0 })
    const [fromHome, setFromHome] = useState(false)

    const [cartSpring, setCartSpring] = useSpring(() => ({ transform: `translateY(-50px)`, immediate: true }))

    /* useEffect(() => {

        mongo.collection && mongo.collection("users").aggregate([{"$addFields" : {"items_id":"609885cd32ae76f2bc329843"}}])
        .then(result => {
            //const { matchedCount, modifiedCount } = result;
            console.log(result)
            return result
        })
        .catch(err => console.error(`Failed to update items: ${err}`))

        //console.log(mongo.collection && mongo.collection("joy_collection").find({}))
       const newItem = {
            name:'main',
            list: minerals
        }

        mongo.collection && mongo.collection("data_lists").insertOne(newItem)
        .then(result => console.log(`Successfully inserted item with _id: ${result.insertedId}`))
        .catch(err => console.error(`Failed to insert item: ${err}`))
    }, [mongo])*/

    useEffect(() => {
        openCart
            ? setCartSpring.start({ transform: `translateY(50px)`, immediate: false })
            : setCartSpring.start({ transform: `translateY(${-cartHeight}px)`, immediate: false })
    }, [openCart, cartHeight, setCartSpring])

    return <>
        <Switch>
            <MainCont>
                <Route exact path={'/'}>
                    <Home {...{
                        dataList,
                        setDataList,
                        setItems,
                        apiEvent,
                        setApiEvent,
                        mongo,
                        itemColls,
                        setFromHome
                    }}
                    />
                </Route>
                <Route path={'/mineral/:mineral'}>
                    <ItemsCont {...{
                        admin,
                        openOverlayA,
                        setOpenOverlayA,
                        setDisableAdminBtn,
                        items,
                        setItems,
                        setCart,
                        openCart,
                        setOpenCart,
                        isItemInCart,
                        setIsItemInCart,
                        dataList,
                        setDataList,
                        apiEvent,
                        setApiEvent,
                        mongo,
                        verified,
                        setVerified,
                        setOpenVerified,
                        favItems,
                        setFavItems,
                        favItemIndx,
                        setFavItemIndx,
                        itemColls, 
						setItemColls,
                        fromHome, 
                        setFromHome
                    }}
                    />
                </Route>
                <Route path={'/about'}>
                    <About />
                </Route>
                <Route path={'/support'}>
                    <Support />
                </Route>
                <Route path={'/privacy'}>
                    <Privacy />
                </Route>
            </MainCont>
        </Switch>
        <Cart {...{
            cart,
            setCart,
            cartSpring,
            setOpenCart,
            openCart,
            setCartHeight,
            setIsItemInCart,
            verified,
            setVerified,
            mongo,
            mongoUser, 
            openVerified,
            setOpenVerified,
            favItems,
            setFavItems,
            favItemIndx,
            setFavItemIndx,
            setOpenOverlayA,
            purchHist, 
            setPurchHist,
            setItems
        }}
        />
        {openSignIn &&
            <Overlay
                open={openSignIn}
                setOpen={setOpenSignIn}
            >
                <SignIn {...{
                    setVerified,
                    setAdmin,
                    isSignIn,
                    setMongo,
                    mongoApp,
                    setMongoApp,
                    setMongoUser,
                    setBtnMin,
                    apiEvent,
                    setApiEvent,
                    setOpenSignIn,
                    setFavItems
                }} />
            </Overlay>
        }
        {btnMin &&
            <PosBtn disabled={disableAdminBtn} onClick={e => { e.preventDefault(); setAdmin(!admin) }} >
                <PosBtnBg />
                {admin
                    ? <FiUnlock color={disableAdminBtn ? '#ccc' : 'black'} size={24} />
                    : <FiLock color={'black'} size={24} />
                }
            </PosBtn>
        }
        <Route path={'/confirmation'}>
            <Confirmation {...{
                mongoApp,
                useLocation,
                useHistory,
                setOpenSignIn,
                setIsSignIn
            }} />
        </Route>
        <Route path={'/reset'}>
        <Reset {...{
                apiEvent,
                setApiEvent,
                mongoApp,
                useLocation,
                useHistory
            }} />
        </Route>
        <NavSide {...{
            sideNavOpen,
            setSideNavOpen,
            setIsSignIn,
            setOpenSignIn,
            verified,
            setVerified,
            setOpenVerified,
            mongoApp,
            setMongo,
            setMongoApp,
            setMongoUser,
            setAdmin,
            btnMin,
            setBtnMin,
            setOpenCart,
            openCart,
            setPurchHist
        }}
        />
        <Navbar {...{
            setOpenCart,
            setSideNavOpen,
            useHistory
        }}
        />
    </>
}