import {useState, useRef} from 'react'
import {updateOne, deleteOne} from '../realm/insertUpdateFind'
import {TopCont, BotCont, Text, TextArea, Number, Label, BtnLabel, BtnLabelCont, FormWrapper, Title} from './addEditStyles'
import useForm from './useForm'
import ItemCollections from './ItemCollections'
import {adminItem} from './forms'
import Btn from '../buttons/Btn'
import { FiEdit, FiFileMinus } from 'react-icons/fi'

export default function EditItem ({
    apiEvent,
    setApiEvent,
    mongo, 
    items, 
    setItems, 
    itemIndx, 
    setOpenOverlayA,
    itemColls, 
    setItemColls
}){

    const [updateOrDelete, setUpdateOrDelete ] = useState(true)
    
    let updatedValues = useRef({})
    let fieldValues = useRef({})
    let once = useRef(true)

    if(once.current){
        once.current = false
        let getTags = Object.values(items[itemIndx].tags.toString())
        let tags = getTags.join('')
        let getImages = Object.values(items[itemIndx].image.toString())
        let images = getImages.join('')
        let fieldKeys = Object.keys(items[itemIndx])
        fieldValues.current = Object.values(items[itemIndx]).map( (val,i) => {
            if(fieldKeys[i] === 'tags') return val = tags
            else if(fieldKeys[i] === 'image') return val = images
            else return val        
        })
    }
    
    const { state, submitHandler, changeHandler } = useForm( adminItem(fieldValues.current), values => {

        setApiEvent(state => ({...state, name:updateOrDelete ? 'update' : 'delete', loading:true}))

        if(updateOrDelete){
            let tags = values.tags.split(',')
            let images = values.image.split(',')
            let price = values.price
            values.tags = tags.map(tag => (tag.toLowerCase().trim()))
            values.image = images.map(img => (img.trim()))
            values.price = parseInt(price, 10)
            updatedValues.current = values

            updateOne(mongo, 'items', items[itemIndx], values, successCallback)
        }
        else{
            deleteOne(mongo, 'items', items[itemIndx], successCallback)
        }
    })

    let successCallback = (success, result) => {

        setApiEvent(state => ({...state, text:'', loading:false}))

        if(success && updateOrDelete){

            let { matchedCount, modifiedCount } = result

            if(matchedCount && modifiedCount) {
                let s = items.slice(0)
                let insertId = {_id:items[itemIndx]._id, ...updatedValues.current}
                s.splice(itemIndx, 1, insertId)
                setItems(s)
                setOpenOverlayA(false)
                setApiEvent(state => ({...state, text:`Successfully updated the item.`}))
            }
            else setApiEvent(state => ({...state, text:`No item was updated.`}))
        }
        else if(success){
            setOpenOverlayA(false)
            setItems(state => {
                let s = state.slice(0)
                s.splice(itemIndx, 1)
                return s
            })
            setApiEvent(state => ({...state, text:`Successfully deleted ${result.deletedCount} item.`}))
            setOpenOverlayA(false)
        }
        else{
            setApiEvent(state => ({...state, text:`Failed to ${updateOrDelete ? 'update' : 'delete'} item. Try again.`}))
        }
    }

    return <>
        {   items[itemIndx] && //not deleted
                <ItemCollections {...{
                    itemColls, 
                    setItemColls,
                    apiEvent,
                    setApiEvent,
                    mongo
                }}
                _id={items[itemIndx]._id}
                item = {items[itemIndx]}
                />
        }
        <TopCont>
            <Title>Update or delete an existing item.</Title>
        </TopCont>
        <form onSubmit={submitHandler}>
            <BotCont>
                <FormWrapper>
                {Object.keys(state).map(key => (
                        (state[key].type === 't1' &&
                        <Label key={key}>{state[key].label}
                            <TextArea 
                            name ="t1"
                            size="1em"
                            minlength="3"
                            maxlength="500"
                            rows="3"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't2' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t2"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't3' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t3"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't4' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t4"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't5' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t5"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't6' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t6"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't7' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t7"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't8' &&
                        <Label key={key}>{state[key].label}
                            <TextArea 
                            name ="t8"
                            size="1em"
                            minlength="4"
                            maxlength="500"
                            rows="3"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>) ||
                        (state[key].type === 't9' &&
                        <Label key={key}>{state[key].label}
                            <Text
                            name ="t9"
                            size="1em"
                            minlength="6"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>) ||
                        (state[key].type === 't10' &&
                        <Label key={key}>{state[key].label}
                            <TextArea
                            name ="t10"
                            size="1em"
                            rows="5"
                            minlength="6"
                            maxlength="5000"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>) ||
                        (state[key].type === 'n1' &&
                        <Label key={key}>{state[key].label}
                            <Number
                            name ="n1"
                            size="1em"
                            minlength="6"
                            maxlength="18"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>)
                        ))}
                        <BtnLabelCont>
                            <BtnLabel>
                                <Btn 
                                text={'UPDATE'} 
                                maxW={'150'} 
                                loading={apiEvent.name === 'update' && apiEvent.loading} 
                                icon={<FiEdit color={'black'} size={21} />}
                                />
                                {apiEvent.name === 'update' && !apiEvent.loading && apiEvent.text}
                            </BtnLabel>
                            <BtnLabel>
                                <Btn 
                                color={'white'} 
                                bg={'rgba(242,	52,	57, 1)'} 
                                text={'DELETE'}
                                maxW={'200'}
                                click={() => setUpdateOrDelete(false)}
                                loading={apiEvent.name === 'delete' && apiEvent.loading}
                                icon={<FiFileMinus color={'black'} size={21} />}
                                />
                                {apiEvent.name === 'delete' && !apiEvent.loading && apiEvent.text}
                            </BtnLabel>
                        </BtnLabelCont>
                    </FormWrapper>
                    </BotCont>
                </form>
            </>
}