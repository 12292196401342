import {useState} from 'react'
import styled from 'styled-components'
import {userSignIn, userSignUp, funcSignIn} from '../realm/realm'
import Btn from '../buttons/Btn'
import useForm from './useForm'
import {signIn} from './forms'
import {updateUserData, find} from '../realm/insertUpdateFind'
import { FiLogIn, FiUser, FiEye, FiEyeOff } from 'react-icons/fi'

const MainCont = styled.section`
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:rgba(14,22,38,1);
    background:linear-gradient(0deg, rgba(14,22,38,0.1) 45%, white 80%);


    .header{
        display:flex;
        align-items:center;
        justify-content:center;
        padding:50px 0 0;
        font-size:2.5rem;
        color:rgba(14,22,38,0.9);


        p{
            margin-left:10px;
        }
    }

    .aside{
        margin:25px 30px 30px;
        font-size:1.3rem;
        width:clamp(300px, 60%, 800px);
        
        p{
            text-align:center;

        }
    }

    .form{
        display:flex;
        flex-direction:column;
        flex-grow:1;
        width:clamp(300px, 60%, 800px);

        label{
            position:relative;
            display:flex;
            align-items:center;
            padding:15px;
            margin-bottom:10px;
            border-radius:12px;
            background: rgba(255,255,255,0.5);
            
            input{
                padding:15px;
                width:100%;
                margin-top:5px;
                background: rgba(14,22,38,0.05);
                border-bottom:1px solid rgba(14,22,38,0.5);


                &:focus{
                    background: white;
                }
            }

            button{
                position:absolute;
                right:30px;
            }
        }

        aside{
            display:flex;
            align-items:center;
            justify-content:space-between;

            p{
                cursor:pointer;
                font-size:0.8rem;
                margin-right:15px;
            }
        }
    }

    .footer{
        display:flex;
        justify-content:flex-end;
        width:100%;
        padding:5px 15px;
        background:rgba(14,22,38,1);
        color:rgba(255,255,255,0.5);
    }
`

export default function SignIn({
    setVerified,
    isSignIn,
    setMongo,
    mongoApp,
    setMongoApp,
    setMongoUser,
    setBtnMin, 
    setAdmin, 
    apiEvent, 
    setApiEvent,
    setOpenSignIn,
    setFavItems
}){
    
    const [forgotActv, setForgotActv] = useState(false)
    const [seePass, setSeePass] = useState(false)

    const { state, submitHandler, changeHandler } = useForm(signIn, async vals => {

        if(forgotActv){
            console.log(vals.email)
            await mongoApp.emailPasswordAuth.sendResetPasswordEmail(vals.email)
            return
        }

        setApiEvent(state => ({...state, name:'signin', text:'Authenticating user...', loading:true}))

        let mongo =  isSignIn ? await userSignIn(vals) : await userSignUp(vals)


        if(isSignIn && mongo.user){ 
        
            await funcSignIn(mongo.user).then(user_type => {
                setOpenSignIn(false)
                if(user_type){
                    setAdmin(user_type)
                    setBtnMin(user_type)
                }
                else if(mongo.user.customData.favs){
                    //setApiEvent(state => ({...state, text:`Welcome back.`}))
                    setVerified({
                        id:mongo.user.id,
                        email:mongo.user.customData.email,
                        customData:mongo.user.customData,
                        favs:mongo.user.customData.favs,
                        purchased:mongo.user.customData.purchased
                    })

                    let findCallback = (success, results) => {
                        if(success){
                            setFavItems(results)
                            setApiEvent(state => ({...state, text:`Welcome back "${mongo.user.customData.name}". 
                            You have "${results.length}" saved Favs.`}))

                        } else console.log(success, results)
                    }

                    let getItems = {
                        "_id" : {"$in":mongo.user.customData.favs} 
                    }
                
                    find(mongo.mongo, 'items', getItems, findCallback)
                }
                else{
                    setApiEvent(state => ({...state, text:`Welcome.`}))

                    let userDataCallback = success => {
                        const customUserData = async () => {
                            let rcd = await mongo.user.refreshCustomData()
                            setVerified({
                                id:mongo.user.id,
                                email:rcd.email,
                                customData:rcd,
                                favs:rcd.favs,
                                purchased:rcd.purchased
                            })
                        }
                        customUserData()
                        setApiEvent(state => ({...state, text: success 
                            ? `Profile created.` 
                            : `Failed to create profile.`
                        }))
                    }

                    updateUserData(mongo.mongo, 'users', { "userId": mongo.user.id }, {"$set":{
                        favs:[],
                        purchased:[], 
                        name: mongo.user._profile.email.substring(0,mongo.user._profile.email.indexOf("@")).replace(/\.\s*([a-z])|^[a-z]/gm, s => s.toUpperCase()),
                        email: mongo.user._profile.email

                    }}, userDataCallback)
                }
            })

            setApiEvent(state => ({...state, loading:false}))
            setMongo(mongo.mongo)
            setMongoApp(mongo.app)
            setMongoUser(mongo.user)
            //history.push(`/`)
            //setSideNavOpen(true)
        }
        else if(isSignIn){
        setApiEvent(state => ({
            ...state, 
            loading:false,
            text:'Sign In failed. "Please try again" or "Sign Up for an acoount".'
        }))

        }
        else{
            setApiEvent(state => ({
                ...state, 
                loading:false,
                text:'We sent you an email. Please respond within the next 30 mins.'
            }))
        }
    })

    let forgotPass = e => {
        e.preventDefault()
        setForgotActv(state => (!state))
        forgotActv
        ? setApiEvent(state => ({...state, name:''}))        
        : setApiEvent(state => ({...state, name:'signin', text:'Enter account email-address. Open email, follow instructions and reset password.'}))
    }

    return <MainCont>
        <header className={'header'}>
            <FiUser color={'rgba(14,22,38,0.3)'} size={80} />
            {
                isSignIn 
                ? forgotActv 
                    ? <p>Send Reset Email</p> 
                    : <p>Sign In</p> 
                : <p>Sign Up</p>
            }
        </header>
        <aside className={'aside'}>
            <p>
                {
                    apiEvent.name === 'signin'
                    ?   apiEvent.text
                    :   isSignIn 
                            ? 'Please enter your "email address and password".' 
                            : 'Please use a "real email address" as you will be required to verify your account with it.'
                }
            </p>
        </aside>
        <form className={'form'} onSubmit={submitHandler}>
        {Object.keys(state).map(key => (
            (state[key].type === 'email' &&
            <label key={key}>
                <input 
                size="1em"
                minLength="3"
                maxLength="30"
                placeholder="Enter your email address."
                id={key} 
                onChange={changeHandler} 
                value={state[key].value}
                autoFocus
                />
            </label> ) ||
            (((state[key].type === 'password' && !forgotActv) || !isSignIn) &&
            <label key={key}>
                <input
                type={seePass ? "text" : "password"}
                size="1em"
                minLength="3"
                maxLength="30"
                placeholder="Enter Password"
                id={key} 
                onChange={changeHandler} 
                value={state[key].value} 
                />
                <button onClick={e => {e.preventDefault(); setSeePass(state => !state)}}>
                    {   seePass               
                        ?   <FiEye 
                            color={'rgba(14,22,38,0.8)'} 
                            size={25}                             
                            />
                        :    <FiEyeOff
                            color={'rgba(14,22,38,0.8)'} 
                            size={25} 
                            />
                    }
                </button>
            </label>
            )
            ))}
            <aside>
                <Btn 
                text={isSignIn ? forgotActv ? 'SEND RESET EMAIL' : 'SIGN IN' : 'SIGN UP'}
                maxW={'200'} 
                loading={apiEvent.loading}
                icon={<FiLogIn color={'black'} size={21} />}
                />
                {   isSignIn && 
                    <p onClick={forgotPass}>
                        {
                            forgotActv ? 'Sign In' : 'I forgot my password'
                        }
                    </p>
                }
            </aside>
        </form>
        <footer className={'footer'}><h5>Rinchen Minerals 2021</h5></footer>
    </MainCont>
}