import {ItemCont} from './itemAdminStylesLA'
import AddItem from '../forms/AddItem'
import EditItem from '../forms/EditItem'

export default function ItemAdminLevelA({
    mongo, 
    setOpenOverlayA, 
    addOrEdit, 
    items, 
    setItems, 
    itemIndx,
    apiEvent,
    setApiEvent,
    itemColls, 
    setItemColls
}) {


    return <ItemCont>
        {
            addOrEdit 
            ? <AddItem {...{
                mongo, 
                apiEvent, 
                setApiEvent
            }} /> 
            : <EditItem {...{
                mongo, 
                items,
                setItems,
                itemIndx, 
                setOpenOverlayA,
                apiEvent,
                setApiEvent,
                itemColls, 
                setItemColls
                }} 
            />
        }
        </ItemCont>
}