import styled from 'styled-components'

export const GemCont = styled.div`
    display:flex;
    align-items:center;
`
export const NavCont = styled.div`
    position:fixed;
    top:0;
    left:0;
    width:100%;
`
export const BlackBg = styled.span`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:50px;
    background: rgba(14,22,38,1);
    box-shadow: 0px 4px 4px rgba(0,0,0,0.3);
    `
    export const NavLeft = styled.div`
    position:absolute;
    top:0;
    left:0;
    width:50vw;
    height:50px;
    display:flex;
    cursor: pointer;
`
export const NavRight = styled.div`
    position:absolute;
    top:0;
    right:0;
    height:50px;
    display:flex;
    justify-content:center;
`
export const TitleTxt = styled.p`
    padding-top:12px;
    font-size: clamp(0.7rem, 3vw, 0.9rem);
    color:rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    line-height:1.04;
    letter-spacing:-0.03rem;
    margin-left:5px;

    &:first-line {
        letter-spacing:-0.04rem;
        font-size: clamp(0.9rem, 4vw, 1.5rem);
        color:rgba(255, 255, 255, 0.8);
    }
`
export const Link = styled.div`
    margin: 0 5px;
    cursor: pointer;
`
export const LinkCont = styled.span`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-evenly;
`
export const CheckoutBtn = styled.button`
    position:relative;
    max-width:150px;
    height:50px;
    font-size: clamp(0.7rem, 2.2vw, 1rem);
    display:flex;
    align-items:center;
    justify-content:center;
    padding:10px;
    margin-left:10px;
    background:rgba(255, 221, 85, 1);
    cursor: pointer;
    `
export const ChipLeft = styled.div`
    position:absolute;
    left:-5px;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-bottom: 50px solid rgba(14,22,38,1);
    background:rgba(255, 221, 85, 1);
`
export const Chip = styled.div`
    margin-top:50px;
    width: 0;
    height: 0;
    border-top: 25px solid rgba(14,22,38,1);
    border-right: 60vw solid transparent;
    filter:drop-shadow(0px 4px 4px rgba(0,0,0,0.3));
`