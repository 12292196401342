import styled from 'styled-components'

export const SearchBarCont = styled.div`
    position:absolute;
    top:50px;
    left:0;
    width:100%;
    display:flex;
	flex-flow:wrap;
    align-items:center;
    justify-content:center;
	padding-top:25px;
	background:rgba(255,255,255,1);
	border-bottom:1px solid rgba(0,0,0,0.1);
    box-shadow: -1px 3px 2px rgba(0,0,0,0.1);
    //z-index:1;
    //filter:drop-shadow(0px 4px 3px rgba(0,0,0,0.3));
    
    &::after{
        content:'';
        position:absolute;
        bottom:-25px;
        left:0;
        right:0;
        //width: 0;
        //height: 0;
        border-top: 25px solid rgba(255,255,255,1);
        border-right: 60vw solid transparent;
        filter:drop-shadow(-1px 4px 2px rgba(0,0,0,0.2));
    }
`
export const SearchCont = styled.div`
	position:relative;
	display:flex;
    align-items:center;
    justify-content:center;
	flex-direction:column;
	width:100%;
`
export const ApiText = styled.div`
    width:100%;
	font-size:1em;
	color:rgba(14,22,38,0.8);
	display:flex;
  	align-items:center;
  	justify-content:center;
`
export const MainCont = styled.div`
    position:relative;
	display:flex;
	flex-direction:column;
	justify-content:center;
	flex-wrap:wrap;
`
export const ItemsCont = styled.div`
    position:relative;
    display:flex;
	justify-content:center;
	flex-wrap:wrap;
	overflow-y:auto;
    height:calc(100vh - 160px);
    margin-top:160px;
    padding-top:35px;
    //padding-bottom:100px;
`
export const ItemCont = styled.div`
	position:relative;
	width:250px;
	height:300px;
	border-radius:5px;
	overflow:hidden;
	margin: 10px 1px;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
`
export const Add = styled(ItemCont)`
    display:flex;
    border: 1px solid rgba(14,22,38,0.5);
`
export const AddUl = styled.ul`
	position:relative;
  	display:flex;
	flex-direction:column;
	width:100%;
`
export const AddLi = styled.li`
	width:100%;
	padding:10px;
	border-radius:3px;
  	display:flex;
  	align-items:center;
  	//justify-content:center;
	cursor: pointer;
  	border-bottom:1px solid rgba(14,22,38,0.1);
  	background:rgba(255, 255, 255, 1);
    
    &:last-child{
  	    border-bottom:1px solid rgba(14,22,38,0);
      }

	&:hover{
		background:rgba(255, 255, 255, 0.5);
	}
`
export const ItemOver = styled.span`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius:5px;
    cursor: pointer;

    &:hover{
        background:rgba(255,255,255,0.1);
    }
`
export const ImageCont = styled.div`
    width:100%;
    height:65%;
    display:flex;
    align-items:flex-end;
    justify-content:center;
    background:white;
    overflow:hidden;
	border-radius:5px 5px 0 0;
`
/*export const Chip = styled.div`
	position:absolute;
	top:-10px;
	right:0;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 300px solid white;
`*/
export const InfoCont = styled.div`
    position:relative;
    display:flex;
    flex-flow:column;
    width:100%;
    height:35%;
    padding:6px 10px;
    background: white;
    font-size:0.8rem;
`
export const Title = styled.p`
    font-size:1rem;
    margin-bottom:4px;
    color:rgba(14,22,38,1);
`
export const Description = styled.p`
    //max-height:50px;
    margin:1px 0;
    line-height:1.27;
    overflow:hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
   color:rgba(14,22,38,0.9);
`
export const Price = styled.p`
    display:flex;
    align-items:flex-end;
    justify-content:flex-end;
    flex-grow:1;

    span{
        color:white;
        padding:0 6px;
        border-radius:10px;
        background:rgba(256, 0, 0, 0.8);
    }
`
export const NoResults = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    margin:100px;
`