import React from "react"
import GemLogo from '../gem/Gem'
import {NavCont, BlackBg, NavLeft, NavRight, TitleTxt, Link, LinkCont, CheckoutBtn, Chip, ChipLeft} from './navBarStyles'
import { FiShoppingCart, FiMenu } from 'react-icons/fi'

export default function Navbar({setOpenCart, setSideNavOpen, useHistory}) {

    const history = useHistory()

    let goHome = () => {
        history.push(`/`)
    }

    return <NavCont>
        <BlackBg />
        <Chip />
        <NavLeft onClick={goHome}>
            <GemLogo />
            <TitleTxt>
                    Rinchen Minerals
                <br/>
                    Discover a World of Crystals
            </TitleTxt>
        </NavLeft>
        <NavRight>
            <LinkCont>
                <Link onClick={e => {
                        e.preventDefault(); 
                        setSideNavOpen(state => !state)
                    }}
                >
                    <FiMenu color={'rgba(255, 255, 255, 0.8)'} size={30} />
                </Link>
            </LinkCont>
            <CheckoutBtn onClick={e => {e.preventDefault(); setOpenCart(state => !state)}}>
                <ChipLeft />
                <FiShoppingCart color={'rgba(14,22,38,1)'} size={24} />
            </CheckoutBtn>
        </NavRight>
    </NavCont>
}