import styled from 'styled-components'

export const MainCont = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:calc(100vh - 50px);
    margin-top:50px;
    overflow-y:auto;
    color:rgba(14,22,38,1);


    .header{
        font-size:2.5rem;
        margin-top:50px;
        padding:20px 60px;
    }

    .main{
        flex-grow:1;
        width:clamp(300px, 80%, 1000px);
        padding:60px 0;
    }

    .article{
        header{
            font-size:2rem;
            margin-bottom:30px;
        }
        p{
            font-size:1.1rem;
            line-height:1.5;
            margin-bottom:30px;

            &:nth-of-type(1){
                font-size:1.5rem;
                color:rgba(14,22,38,0.8);
            }

        }
        span{
            display:block;
            width:100%;
            padding:10px;
            margin-bottom:15px;
            background:rgba(14,22,38,0.7);
            border-radius:5px;
            color:white;
        }
    }
`
export const HeaderImgCont = styled.div`
    display:flex;
    width:clamp(300px,80%, 1000px);
    margin-top:30px;

    .img-tall-article{
        align-self:stretch;
        width:400px;
    }

    .img-row-article{
        display:flex;
        flex-flow:wrap;
    }    
`


/*
export const TxtCont = styled.div`
    line-height:1.4;
    position:relative;
    width:100%;
    color:rgba(14,22,38,1);

    >*:nth-of-type(1) {
        font-size:1.3rem;
        color:rgba(14,22,38,1);
    }
    >*:nth-of-type(3) {
        font-size:1.3rem;
        color:rgba(14,22,38,0.8);
    }
`
*/
