import styled from 'styled-components'

export const ItemCont = styled.div`
    position:relative;
    width:100%;
    height:100%;
  `
  export const ItemOver = styled.span`
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border-radius:5px;
    cursor: pointer;

    &:hover{
        //border: 2px solid rgba(255, 204, 0, 1);
        background:rgba(255, 221, 85, 0.1);
    }
`
  export const ImageCont = styled.div`
    width:100%;
    height:55%;
    padding:5px;
    justify-content:space-evenly;
    background:rgba(255, 204, 0, 1);
`
export const InfoCont = styled.div`
    width:100%;
    height:45%;
    padding:5px;
    color:white;
    background:black;
`
export const Title = styled.span`
    display:block;
    font-size:1.2rem;
    width:100%;
`
export const Description = styled.span`
    font-size:0.9rem;
    width:100%;
`