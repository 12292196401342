import {useState, useEffect} from 'react'
import styled from 'styled-components'

const MainCont = styled.div`
    width:100%;
    height:100%;
    background:linear-gradient(0deg, rgba(14,22,38,0.1) 55%, white 75%);

    .header{
        display:flex;
        align-items:center;
        justify-content:center;
        padding:50px 30px 10px;
        color:rgba(14,22,38,1);
    }

    .main{
        .section{
            display:flex;
            flex-flow:wrap;
            padding:15px;

            .article-l{   
                padding:15px;

                .ul-l{
                    border:1px solid rgba(14,22,38,0.5);
                    border-radius:12px 0 0 12px;
                    margin-top:15px;
                    max-height:300px;
                    overflow-y:auto;
                background:white;

                }
            }
            .article-r{
                flex-grow:1;
                background:white;
                padding:25px;
                margin:15px 0;
                border-radius:0 12px 12px 0 ;
                border:1px solid rgba(14,22,38,0.5);

                li{
                    width:100%;
                    margin-bottom:10px;

                    &::first-line{
                        text-transform: uppercase;
                        font-size: 0.8rem;
                        color:rgba(14,22,38,0.8);
                    }
                }
            }
        }
    }
`
const Lil = styled.li`
    padding:15px;
    border-bottom:1px solid rgba(14,22,38,0.5);
    cursor:pointer;
    color:${props => props.clicked === 'true' ? 'white' : 'rgba(14,22,38,1)'};
    background:${props => props.clicked === 'true' ? 'rgba(14,22,38,0.7)' : 'none'};
    
    &:last-child{
        border-bottom:0;

    }
`

export default function ViewTransactions({
    mongo
}){

    const [details, setDetails] = useState({})
    const [orderIds, setOrderIds] = useState([])
    const [hilite, setHilite] = useState(-1)

    let getDetails = (orderid, indx) => e => {
        e.preventDefault()
        
        setHilite(indx)
        mongo.collection('transactions')
        .find({"order.id":orderid})
        .then(result => {

            const {id, purchase_units:[{
                    custom_id, 
                    amount:{
                        value,
                        currency_code
                    },
                    payee:{
                        email_address,
                        merchant_id
                    },
                    items
                }],
                payer:{
                    name:{
                        given_name, 
                        surname
                    }, 
                    //email_address, 
                    //payer_id, 
                    //address:{country_code}
                },
                create_time
    
            } = result[0].order
    
            setDetails({
                id,
                custom_id,
                item_ids:items.map(itm => (itm.description)),
                itemRefs:items.map(itm => (itm.sku)),
                value,
                currency_code,
                contact:email_address,
                merchant_id, 
                given_name,
                surname, 
                create_time
            })
        })
        .catch(console.log('err getDetails'))
    }

    let {
        id,
        custom_id,
        //item_ids,
        itemRefs,
        value,
        currency_code,
        contact,
        merchant_id, 
        given_name,
        surname, 
        create_time
    } = details || null

    useEffect(() => {
        mongo.collection('transactions')
        .find({"name":"orderDetails"})
        .then(results => {
            setOrderIds(results[0].allOrderIds)
        })
        .catch(console.log('err orderDetails'))

    }, [mongo])

    return <MainCont>
        <header className={'header'}>
            <h1>View All Transactions</h1>
        </header>
        <main className={'main'}>
            <section className={'section'}>
                <article className={'article-l'}>
                    <h3>Please choose an ID to view details.</h3>
                    <ul className={'ul-l'}>
                        {[...orderIds].reverse().map((id,i) => (
                            <Lil key={'ids'+i} onClick={getDetails(id, i)} clicked={i === hilite ? 'true' : null}>{id}</Lil>
                        ))}
                    </ul>
                </article>
                <article className={'article-r'}>
                    {
                        details.given_name
                        ?   <ul>
                                <li>Registered User:<br/> {custom_id}</li>
                                <li>Name:<br/> {`${given_name} ${surname}`}</li>
                                <li>Date and time of purchase:<br/> {new Date(create_time).toString()}</li>
                                <li>Number of Items Purchased:<br/> {itemRefs.length}</li>
                                <li>Purchase References:<br/> {itemRefs.map(itm => (`${itm} `))}</li>
                                <li>PayPal order-Id:<br/> {id}</li>
                                <li>Total:<br/> {`${value} ${currency_code}`}</li>
                                <li>---------------</li>
                                <li>Rinchen Minerals</li>
                                <li>Email:<br/> {contact}</li>
                                <li>PayPal Merchant Id:<br/> {merchant_id}</li>
                            </ul>
                        :   <h3>Click on an ID to get details.</h3>
                    }
                </article>
            </section>
        </main>
    </MainCont>
}