import { useState, useEffect, useCallback, useRef } from "react"
import {Add, AddUl, AddLi, ItemsCont, MainCont, SearchBarCont, SearchCont, ApiText, NoResults} from './itemStyles'
import SearchBar from '../forms/SearchBar'
import Item from './Item'
import Overlay from '../overlay/Overlay'
import ItemLevelA from '../item/ItemLevelA'
import ItemAdminLevelA from '../admin/ItemAdminLevelA'
import Footer from '../footer/Footer'
import ViewTransactions from '../admin/ViewTransactions'
import ViewUsers from '../admin/ViewUsers'
import {find} from '../realm/insertUpdateFind'
import EditDataList from '../forms/EditDataList'
import { FiFilePlus, FiEdit, FiInfo, FiAlertTriangle, FiSearch, FiShoppingCart, FiUsers } from 'react-icons/fi'
import {Route, useHistory, useParams } from "react-router-dom"

export default function ItemsContainer({
    admin,
    setDisableAdminBtn,
    setItems,
    items,
    openOverlayA, 
    setOpenOverlayA,
    setCart,
    openCart,
    setOpenCart,
    isItemInCart,
    setIsItemInCart,
    dataList,
    setDataList,
    apiEvent,
    setApiEvent,
    mongo,
    verified,
    setVerified,
    favItems,
    setFavItems,
    setOpenVerified,
    favItemIndx,
    setFavItemIndx,
    itemColls, 
    setItemColls,
    fromHome, 
    setFromHome
}) {
    //const Item = lazy(() => import('./Item'))

    //const { path, url } = useRouteMatch()
    //let introOnce = useRef(true)
    let isMineralSame = useRef('')
    const history = useHistory()
    const { mineral } = useParams()
    let mineral_lc = mineral.toLowerCase()

    const [gotItemsIcns, setGotItemsIcns] = useState(true)
    const [itemIndx, setItemIndx] = useState(0)
    const [addOrEdit, setAddOrEdit] = useState(true)
    const [editDataList, setEditDataList] = useState(false)
    const [viewTrans, setViewTrans] = useState(false)
    const [viewUsers, setViewUsers] = useState(false)

    let openOverA = indx => e => {
        e.preventDefault()

        if(typeof indx === 'number'){
            setAddOrEdit(false)
            setItemIndx(indx)
        }
        else if(admin){

            if(indx === 'add-new'){
                setAddOrEdit(true)
            }
            else if(indx === 'edit-list'){
                setEditDataList(true)
            }
            else if(indx === 'view-trans'){
                setViewTrans(true)
            }
            else if(indx === 'view-users'){
                setViewUsers(true)
            }

            setDisableAdminBtn(true) 
        }
        verified.favs && favItemIndx.active && setFavItemIndx(state => {
            state.active = false
            return state
        })
        history.push(`/mineral/${mineral_lc}/${indx}`)
        //setOpenOverlayA(true)
        //setApiEvent(state => ({...state, name:''}))
    }

    let onClose = () => {
        setDisableAdminBtn(false)
        setEditDataList(false)
        setViewUsers(false)
        setViewTrans(false)
        history.push(`/mineral/${mineral_lc}`)
    }

    let findCallback = useCallback((success, results) => {

        setApiEvent(state => ({...state, name:'search', loading:false}))
        if(success){
            setGotItemsIcns(items.length === 0 ? false : true)
            setItems(results)
            setApiEvent(state => ({...state, text:`There are "${results.length}" results for "${mineral.toUpperCase()}".`}))            
            !openOverlayA && setOpenOverlayA(true)
        }else{
            setGotItemsIcns(false)
            setApiEvent(state => ({...state, text:`Your search stopped, try again!`}))
        }
    }, [openOverlayA, setOpenOverlayA, setItems, setApiEvent, mineral, items.length])
    
    useEffect(() => {
        if(isMineralSame.current !== mineral_lc && mongo.collection && !fromHome) {
            find(mongo, 'items', {tags:{"$in":[mineral_lc]}}, findCallback)
            isMineralSame.current = mineral_lc
        }
        else if(fromHome){ 
            setFromHome(false)
            isMineralSame.current = mineral_lc
        }
    }, [mineral_lc, findCallback, mongo, fromHome, setFromHome])

    return <>
        <MainCont>
            <ItemsCont>
                {admin &&
                    <Add>
                        <AddUl>
                            <AddLi onClick={openOverA('add-new')}><FiFilePlus color={'rgba(14,22,38,0.5)'} size={36} />
                                Add New Item
                            </AddLi>
                            <AddLi onClick={openOverA('edit-list')}><FiEdit color={'rgba(14,22,38,0.5)'} size={36} />
                                Edit Data List
                            </AddLi>
                            <AddLi onClick={openOverA('view-trans')}><FiShoppingCart color={'rgba(14,22,38,0.5)'} size={36} />
                                View Transactions
                            </AddLi>
                            <AddLi onClick={openOverA('view-users')}><FiUsers color={'rgba(14,22,38,0.5)'} size={36} />
                                View Users
                            </AddLi>
                        </AddUl>                        
                    </Add>
                }
                { items.length > 0
                    ?   items.map((item, i) => ( 
                            <div key={`c${i}`} onClick={openOverA(i)}>
                                <Item  {...{item}} />
                            </div>
                        ))
                    :   <NoResults>
                            {   gotItemsIcns
                                ?   <FiSearch color={'rgba(14,22,38,0.5)'} size={150} />
                                :   <FiAlertTriangle color={'rgba(14,22,38,0.2)'} size={300} />
                            }
                        </NoResults>
                }
                <Footer />
            </ItemsCont>
            <SearchBarCont>
                <SearchCont>
                    <SearchBar {...{
                            mongo,
                            dataList, 
                            setDataList,
                            apiEvent,
                            setApiEvent,
                            useHistory,
                            setItems,
                            setGotItemsIcns
                        }}
                    />
                </SearchCont>
                <ApiText>
                    <FiInfo color={'rgba(14,22,38,0.5)'} size={24} style={{marginRight:'5px'}} /> 
                    {apiEvent.name === 'search' ? apiEvent.text : 'Try search for more results!'}
                </ApiText>
            </SearchBarCont>
        </MainCont>
        <Route path={`/mineral/${mineral_lc}/:item_index`}>
            <Overlay
            open={openOverlayA}
            setOpen={setOpenOverlayA}
            onClose={onClose}
            >
                {
                    admin 
                    ?   editDataList || viewTrans || viewUsers
                        ?   editDataList
                            ?   <EditDataList {...{
                                    dataList, 
                                    setDataList,
                                    apiEvent,
                                    setApiEvent,
                                    mongo
                                    }} 
                                /> 
                            :   viewTrans
                                ?   <ViewTransactions {...{
                                        mongo
                                        }} 
                                    />
                                :   <ViewUsers {...{
                                        mongo
                                        }} 
                                    />
                        :  <ItemAdminLevelA {...{
                                items,
                                setItems, 
                                itemIndx,
                                addOrEdit,
                                setOpenOverlayA,
                                setEditDataList,
                                apiEvent,
                                setApiEvent,
                                mongo,
                                itemColls, 
                                setItemColls
                                }} 
                            /> 
                    : items.length > 0 ? <ItemLevelA {...{
                        items, 
                        itemIndx,
                        setCart, 
                        openCart,
                        setOpenCart,
                        isItemInCart, 
                        setIsItemInCart,
                        mongo,
                        verified,
                        setVerified,
                        favItems,
                        setFavItems,
                        setOpenVerified,
                        favItemIndx,
                        setFavItemIndx
                        }}
                    />
                    :<div>loading...</div>
                }
            </Overlay>
        </Route>
    </>
}