import {MainCont} from './footerStyles'
import { useHistory } from "react-router-dom"
import {FiChevronRight} from 'react-icons/fi'


export default function Footer(){
    
    const history = useHistory()

    const goHome = e => {
        e.preventDefault()
        history.push(`/`)
    }

    const about = e => {
        e.preventDefault()
        history.push(`/about`)
    }

    const support = e => {
        e.preventDefault()
        history.push(`/support`)
    }

    const privacy = e => {
        e.preventDefault()
        history.push(`/privacy`)
    }

    return(
        <MainCont>
            <footer className={'footer'}>
                <span onClick={goHome}><FiChevronRight color={'rgba(14,22,38,0.3)'} size={18} /> Home</span>
                <span onClick={about}><FiChevronRight color={'rgba(14,22,38,0.3)'} size={18} />About</span>
                <span onClick={support}><FiChevronRight color={'rgba(14,22,38,0.3)'} size={18} />Contact/Impressum</span>
                <span onClick={privacy}><FiChevronRight color={'rgba(14,22,38,0.3)'} size={18} />Privacy</span>
                <p>Rinchen Minerals 2022</p>
            </footer>
        </MainCont>
    )

}