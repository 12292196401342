import {Image, Transformation} from 'cloudinary-react'
import {ItemCont, ItemOver, ImageCont, InfoCont, Title, Description, Price} from './itemStyles'

export default function Item({item:{image, title, desc, price}}) {

    //console.log(db.mongo.db("joy_db").collection("joy_collection").find({}))

    return <>
            <ItemCont>
                <ImageCont>
                    <Image cloud_name='rinchen' publicId={image[0]} >
                        <Transformation 
                        effect="sharpen:70" 
                        width="300" 
                        crop="scale"
                        quality="auto" 
                        fetchFormat="auto" 
                        /> 
                    </Image>
                </ImageCont>
                <InfoCont>
                    <Title>{title}</Title>
                    <Description>{desc}</Description>
                    <Price> {price > 0 ? `£ ${price} `: <span>SOLD</span>}</Price>
                </InfoCont>
                <ItemOver />
            </ItemCont>
        </>



}