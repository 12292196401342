import {useEffect} from 'react'
import {MainCont, Close, SideNavOverflow} from './navSideStyles'
import {useSpring} from '@react-spring/web'
import SideCont from './SideCont'

export default function NavSide({
    sideNavOpen, 
    setSideNavOpen, 
    setIsSignIn, 
    setOpenSignIn,
    verified,
    setVerified,
    setOpenVerified,
    mongoApp,
    setMongo,
    setMongoApp,
    setMongoUser,
    setAdmin, 
    btnMin,
    setBtnMin,
    setOpenCart,
    openCart,
    setPurchHist

}){
  	const [closeSpring, setCloseSpring] = useSpring(() =>({opacity:'0', visibility:'hidden', onRest:() => null}))
    const [slide, setSlide] = useSpring(() => ({right:'-245px'}))

    useEffect(() => {
        setSlide.start({right:sideNavOpen ? '0px' :'-245px'})
        sideNavOpen
        ? setCloseSpring.start({opacity:'1', visibility:'visible', onRest:() => null})
        : setCloseSpring.start({opacity:'0', onRest:() => setCloseSpring.start({visibility:'hidden'})})
    }, [sideNavOpen, setSlide, setCloseSpring])

    return <SideNavOverflow>
                <Close style={closeSpring} onClick={e => {
                    e.preventDefault()
                    setSideNavOpen(false)
                    }} 
                    />
                <MainCont style={slide}>
                    <SideCont {...{
                        setIsSignIn, 
                        setOpenSignIn, 
                        setSideNavOpen,
                        verified,
                        setVerified,
                        setOpenVerified,
                        mongoApp,
                        setMongo,
                        setMongoApp,
                        setMongoUser,
                        setAdmin,
                        btnMin,
                        setBtnMin,
                        setOpenCart,
                        openCart,
                        setPurchHist

                    }} />
            </MainCont>
     </SideNavOverflow>
}