import styled from 'styled-components'

export const TopCont = styled.div`
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	width:100%;
	//height:100%;
	//height:clamp(300px, 60vh, 1525px);
	background:white;
`
export const MidCont = styled.div`
	width:100%;
	//height:auto;
	padding-bottom:100px;
`
export const Group = styled.div`
	display:flex;
	flex-flow:wrap;
	justify-content:space-between;
`
export const Ul = styled.ul`
	position:relative;
  	display:flex;
	flex-direction:column;
	min-width:30%;
	//max-width:50%;
	//width:clamp(30%, 400px, 100%);
	flex-grow:1;
	margin:15px;
`
export const Li = styled.li`
	width:100%;
	padding:10px;
	border-radius:3px;
  	display:flex;
  	align-items:center;
  	border-bottom:1px solid rgba(14,22,38,0.5);
  	background:rgba(255, 255, 255, 1);
`
export const Lip = styled.p`
	width:100%;
	line-height:1.4;
	&::first-line{
		text-transform: uppercase;
		font-size: 0.8rem;
		color:rgba(14,22,38,0.8);
	}

	span{
        color:white;
        padding:0 6px;
        border-radius:10px;
        background:rgba(256, 0, 0, 0.8);
    }
`
export const Title = styled.div`
	display:flex;
	flex-grow:1;
	align-items:center;
	//padding:20px clamp(10px, 12vw, 1200px) 20px;
	padding:20px;
    font-size: clamp(1rem, 5vw, 1.3rem);
	color:rgba(14,22,38,1);
`
export const Price = styled.span`
  	font-size:1.2rem;
	padding:10px;
	background:white;
`
export const CartBtn = styled.div`
	//height:auto;
	display:flex;
	//flex-flow:wrap-reverse;
	align-items:center;
	//justify-content:center;
	//padding-left:15px;
	//background:rgba(255, 230, 128, 0.5);
`
export const Desc = styled.p`
    line-height:1.5;
	//padding:20px clamp(10px, 12vw, 1200px) 20px;
	padding:20px;
	color:rgba(14,22,38,0.9);
	max-width:500px;

	&::first-line{
		font-size: 1.2rem;
	}
`