import styled from 'styled-components'
import { FiCheckCircle, FiMessageCircle, FiTruck, FiThumbsUp, FiX } from 'react-icons/fi'
const MainCont = styled.div`
    position:relative;
    display:flex;
    flex-flow:wrap;
    justify-content:center;
    padding:30px;
    line-height:1.5;
    color:rgba(14,22,38, 1);
    background:linear-gradient(0deg, rgba(78, 154, 6, 0.1) 55%, white 75%);

    section{
        display:flex;
        flex-flow:wrap;
        justify-content:center;
        width:30%;
        min-width:210px;
        padding:20px;
        margin:10px;
        border:1px solid rgba(78, 154, 6, 0.5);
        border-radius:12px;
        background:white;
    }

    header{
        display:flex;
        align-items:center;
        justify-content:center;
        width:100%;
        padding:30px;
    }

    p{
        display:flex;
        flex-direction:column;
        font-size:0.9rem;
    }

    b{
        margin-top:20px;
    }

    span{
        font-size:1.3rem;
        padding-bottom:30px;
        margin-left:10px;

        &.subHead {
            margin-top:30px;
            width:100%;
        }
    }

    ul {
        width:100%;
        list-style: none;
        padding: 0px 20px;
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top: 3px solid rgba(78, 154, 6, 0.7);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
            li {
            padding: 10px;
            border-top: 1px solid gray;

            &.divide {
                background:rgba(78, 154, 6, 1);
                //font-size:1rem;
                font-weight: 700;
                color:white;
            }

            :first-of-type {
                border-top: none;
            }
        }
    }
`
const Close = styled.div`
    position:absolute;
    top:15px;
    right:15px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    background:rgba(78, 154, 6, 0.1);
    padding:8px;
    cursor:pointer;

    &:hover{
        background:rgba(78, 154, 6, 0.3);
    }
`

export default function Approved({setCart, setCheckout, checkout:{
    user, 
    amount:{
        item_total, 
        shipping, 
        handling, 
        insurance, 
        shipping_discount
    }, 
    payee, 
    items, 
    payer:{
        name:{
            given_name, 
            surname
        }, 
        email_address, 
        payer_id, 
        address:{country_code}
    }, 
    create_time
}}){

    let emailPayee = payee.email_address.split('@')
    let email = email_address.split('@')

    let close = e => {
        e.preventDefault()
        setCheckout({})
    }

    return <MainCont>
        <header>
            <FiCheckCircle color={'rgba(78, 154, 6, 0.7)'} size={70}/>
            <span>Thank You for your Support {given_name}!</span>
        </header><br/>
        <section>
            <FiTruck color={'rgba(78, 154, 6, 0.7)'} size={50}/>
            <p> We will send an email to the address below as soon as your items have shipped.
                <b>{email[0]} <br/> @{email[1]}</b>
            </p>
        </section>
        <section>
            <FiMessageCircle color={'rgba(78, 154, 6, 0.7)'} size={50}/>
            <p> Please contact us if you incounter issues with your order at:
                <b>{emailPayee[0]} <br/> @{emailPayee[1]}</b> 
            </p>
        </section>
        <section>
            <FiThumbsUp color={'rgba(78, 154, 6, 0.7)'} size={50}/>
            <p>We very much hope you are happy with your choice of mineral gems and look forward to seeing you again.</p>
        </section>
        <span className="subHead">PAYPAL ORDER DETAILS:</span>
        <ul>
            <li>Registered User: {user}</li>
            <li>Date: {new Date(create_time).toString()}</li>
            <li className="divide">Order for: {given_name} {surname}</li>
            <li>Payer Id: {payer_id}</li>
            <li>Email: {email_address} </li>
            <li>Address: {country_code} </li>
            <li>Items Purchased: You have purchesed {items.length} items.</li>
            <li>Shipping: {shipping.value}</li>
            <li>Handling: {handling.value}</li>
            <li>Insurance: {insurance.value}</li>
            <li>Shipping discount: {shipping_discount.value}</li>
            <li>Amount Total: {item_total.value}</li>
            <li className="divide">Ordered from Rinchen Minerals:</li>
            <li>Email: {payee.email_address}</li>
            <li>Merchant Id: {payee.merchant_id}</li>
        </ul>
        <Close onClick={close}><FiX size={24} color={'black'} /></Close>
    </MainCont>
}