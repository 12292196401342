export function insertOne(mongo, collection, newItem, callback){

	mongo.collection(collection).insertOne(newItem)
		.then(result => callback(true, result))
		.catch(err => callback(false,err))
}

export function updateOne(mongo, collection, query, updateItem, callback){
	let update = {
		"$set": updateItem
	  }
	let options = { "upsert": false }

	mongo.collection(collection).updateOne(query, collection === 'items' ? update : updateItem, options)
	.then(result => callback(true, result))
	.catch(err => callback(false,err))
}

export function updateUserData(mongo, collection, query, updateItem, callback){

	let options = { "upsert": true }

	mongo.collection(collection).updateOne(query, updateItem, options)
	.then(result => callback(true, result))
	.catch(err => callback(false,err))
}

export function find(mongo, collection, query, callback){	

	mongo.collection(collection).find(query, {limit: 300})
	.then(result => callback(true, result))
	.catch(err => callback(false,err))
}

export function deleteOne(mongo, collection, deleteItem, callback){

	mongo.collection(collection).deleteOne( deleteItem )
		.then(result => callback(true, result))
		.catch(err => callback(false, err))
}

const track = []
export function getCollectionDataLists(mongo, collection, query, callback){

    if(track.find(secTitle => (secTitle === query.name))) return

    mongo.collection(collection)
    .findOne(query, {projection:{ "_id": 0 }})
    .then(result => {
        if(result) {
			callback(true, result)
        } else {
            console.log("No document matches the provided query.")
        }
    })
    .catch(err => callback(false, err))

    track.push(query.name)
}