import {useRef} from 'react'
import {MainCont} from './supportStyles'
import { FiCheckCircle, FiGift, FiTruck, FiClock, FiSmile, FiHeart, FiInfo, FiChevronUp} from 'react-icons/fi'
import {Image, Transformation} from 'cloudinary-react'
import Footer from '../footer/Footer'

export default function Support(){

    const refMainCont = useRef()
    const refContact = useRef()
    const refPostage = useRef()
    const refMember = useRef()
    const refGemA = useRef()
    const refDisclamer = useRef()

    let pageLink = link => e => {
        e.preventDefault()
        link.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
    }

    let goTop = e => {
        e.preventDefault()
        refMainCont.current.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return(
        <MainCont ref={refMainCont}>
            <header className={'header'}>
               Support & Information 
            </header>
            <main className={'main'}>
                <aside className={'aside'}>
                    <ul>
                        <li onClick={pageLink(refContact)}>Contact</li>
                        <li onClick={pageLink(refPostage)}>Postage</li>
                        <li onClick={pageLink(refMember)}>Member</li>
                        <li onClick={pageLink(refGemA)}>Gem-A</li>
                        <li onClick={pageLink(refDisclamer)}>Disclamer</li>
                    </ul>
                    <div className={'icons'}>
                        <FiInfo size={60} color={'rgba(255,255,255,0.7)'} />
                        <FiCheckCircle size={60} color={'rgba(255,255,255,0.7)'} />
                        <FiGift size={60} color={'rgba(255,255,255,0.7)'} />
                        <FiTruck size={60} color={'rgba(255,255,255,0.7)'} />
                        <FiClock size={60} color={'rgba(255,255,255,0.7)'} />
                        <FiSmile size={60} color={'rgba(255,255,255,0.7)'} />
                        <FiHeart size={60} color={'rgba(255,255,255,0.7)'} />
                    </div>
                </aside>
                <article className={'article'}> 
                    <br ref={refContact}/><br/>                   
                    <span>CONTACT INFORMATION
                        <b onClick={goTop}>
                            <FiChevronUp size={30} color={'rgba(255,255,255,0.7)'} />
                        </b>
                    </span>
                    <p>
                        Rinchen Minerals<br/>
                        Stephen Ramos-Gonzalez<br/>
                        info@rinchenminerals.com<br/>
                        Hannoversche Strasse 80A<br/>
                        30823 Garbsen<br/>
                        Germany<br/><br/>
                        Tax number: 03241005
                    </p>
                    <p>
                        <a className={'email-btn'} href = "mailto:info@rinchenminerals.com?subject = Message from Rinchen Minerals.com">
                            <span>info@rinchenminerals.com</span>
                        </a>
                    </p>                    
                    <p>I buy individual specimens from personal collections and I buy whole collections.  If you have something to offer, please email me with details.</p>
                    <br ref={refPostage}/><br/>
                    <span>POSTAGE & RETURNS
                        <b onClick={goTop}>
                            <FiChevronUp size={30} color={'rgba(255,255,255,0.7)'} />
                        </b>
                    </span>
                    <p>
                        The charges for parcels being sent within the European Union, postage is £6
                        for any purchase below £30 and free for any purchase of £30 and over.
                        <br/>
                        For parcels being sent outside of the European Union, the charges are £15
                        for any purchase below £50 and free for any purchase of £50 and over.
                    </p>
                    <br ref={refMember}/><br/>
                    <span>BECOME A MEMBER
                        <b onClick={goTop}>
                            <FiChevronUp size={30} color={'rgba(255,255,255,0.7)'} />
                        </b>
                    </span>
                    <p>Anyone can purchase an item from the website, regardless of whether or not they become a member.</p>
                    <p>The advantages of becoming a member are, you can save “favourites” for later; there will be an occasional newsletter sent out – giving an “early bird” opportunity to buy new stock and discount codes will be offered to members.</p>
                    <br ref={refGemA}/><br/>
                    <div className={'logo'}>
                        <span>GEM-A
                            <b onClick={goTop}>
                                <FiChevronUp size={30} color={'rgba(255,255,255,0.7)'} />
                            </b>
                        </span>
                        <p>The Gemmological Association of Great Britain – now called Gem-A – are the oldest school of gemmology in the world.  They offer the best gemmology diplomas available.  They are based at:</p>
                        <p>21 Ely Place
                        London
                        EC1N 6TD
                        United Kingdom</p>
                        <p>They have branches all over the world.</p>
                        <Image cloud_name='rinchen' publicId={'Miscellaneous/Gem-ALogo'} >
                            <Transformation effect="sharpen:70" width="132" crop="scale" />
                        </Image>
                    </div>
                    <br ref={refDisclamer}/><br/>
                    <span>DISCLAMER
                        <b onClick={goTop}>
                            <FiChevronUp size={30} color={'rgba(255,255,255,0.7)'} />
                        </b>
                    </span>
                    <p>All measurements given are approximate.  I have tried to be correct to within 1mm but, due to the natural and irregular features of many specimens, this cannot be guaranteed.</p>
                    <p>All phone, tablet and computer screens are different, so I cannot guarantee that your screen is giving you an accurate colour reproduction of the specimens.  I have done my best to make it as close to nature on my own screen.  Because of natural properties of some minerals, I have found it impossible to take photos showing their correct colour but, in these cases I have made a mention of this in my description.</p>
                    <p>Unless otherwise stated in the description, any stand or other accessory that can be seen in any photo is not included with the item.</p>
                    <p>My specimens all come with a life time guarantee that they are what I have claimed them to be.  I have described each item as accurately as I can and I hope that I have not made any mistakes but, due to human error, it is always possible that a specimen has been wrongly identified or that I have accidently attached the wrong details to an item.  In which case I apologise and I would offer a full refund including the return postage.  I stress, there is no time limit on this guarantee.</p>
                    <p>Also, any item may be returned, in original condition, within 14 days of receiving it, for a full refund, no questions asked."</p>
                </article>
            </main>
            <Footer />
        </MainCont>
    )
}