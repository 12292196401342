import styled from 'styled-components'
import {animated} from '@react-spring/web'

export const Close = styled(animated.div)`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
	background:rgba(14,22,38, 0.5);
    cursor:pointer;
    pointer-events:all;
`
export const MainCont = styled(animated.div)`
    position:absolute;
    top:50px;
    width:225px;
    height:calc(100vh - 50px);
    background:rgba(14,22,38,0.95);
    color:white;
    pointer-events:all;

    &::before{
        content:'';
        position:absolute;
        left:-20px;
        width: 0;
        height: 0;
        border-right: 20px solid rgba(14,22,38,0.95);
        border-bottom: 60vh solid transparent;
        //filter:drop-shadow(0px 4px 4px rgba(0,0,0,0.3));
    }
`
export const SideNavOverflow = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
    pointer-events:none;
`