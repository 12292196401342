import Favs from './Favs'


export default function UserCont({
    verified, 
    favItems, 
    setFavItems, 
    favItemIndx,
    setFavItemIndx, 
    setVerified, 
    setOpenOverlayA,
    mongo
}){

    return<>
        <Favs {...{
                verified, 
                favItems,
                setFavItems, 
                favItemIndx,
                setFavItemIndx, 
                setVerified, 
                setOpenOverlayA,
                mongo
        }} />
    </>

}