import styled from 'styled-components'

export const MainCont = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:calc(100vh - 50px);
    margin-top:50px;
    overflow-y:auto;
    color:rgba(14,22,38,1);
    line-height:1.3s;

    .header{
        font-size:2.5rem;
        margin-top:50px;
        padding:30px 50px;
    }

    .icons{
        flex-grow:1;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-flow:wrap;
        justify-content:space-evenly;
        min-width:250px;
        
        >*{
            margin:10px;
        }
    }

    .main{
        flex-grow:1;
        width:clamp(300px, 80%, 1000px);
        padding:30px 0;
    }

    .text-XL{
        line-height:1.5;
        font-size:1.1rem;
        color:rgba(14,22,38,0.7);
    }

    .article{
        margin-bottom:60px;

        p{
            margin-bottom:15px;
        }
        h3, h4{
            margin-bottom:10px;
        }
    }
`
