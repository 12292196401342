import {useState, useEffect} from 'react'
import styled from 'styled-components'

const MainCont = styled.div`
    width:100%;
    height:100%;
    background:linear-gradient(0deg, rgba(14,22,38,0.1) 55%, white 75%);

    .header{
        display:flex;
        align-items:center;
        justify-content:center;
        padding:50px 30px 10px;
        color:rgba(14,22,38,1);
    }

    .main{
        .section{
            display:flex;
            flex-flow:wrap;
            padding:15px;

            .article-l{   
                padding:15px;

                .ul-l{
                    border:1px solid rgba(14,22,38,0.5);
                    border-radius:12px 0 0 12px;
                    margin-top:15px;
                    max-height:300px;
                    overflow-y:auto;
                background:white;

                }
            }
            .article-r{
                flex-grow:1;
                background:white;
                padding:25px;
                margin:15px 0;
                border-radius:0 12px 12px 0 ;
                border:1px solid rgba(14,22,38,0.5);

                li{
                    width:100%;
                    margin-bottom:10px;

                    &::first-line{
                        text-transform: uppercase;
                        font-size: 0.8rem;
                        color:rgba(14,22,38,0.8);
                    }
                }
            }
        }
    }
`
const Lil = styled.li`
    padding:15px;
    border-bottom:1px solid rgba(14,22,38,0.5);
    cursor:pointer;
    color:${props => props.clicked === 'true' ? 'white' : 'rgba(14,22,38,1)'};
    background:${props => props.clicked === 'true' ? 'rgba(14,22,38,0.7)' : 'none'};
    
    &:last-child{
        border-bottom:0;

    }
`

export default function ViewUsers({
    mongo
}){

    const [details, setDetails] = useState({})
    const [userEmails, setUserEmails] = useState([])
    const [hilite, setHilite] = useState(-1)

    let getDetails = (emailAdress, indx) => e => {
        e.preventDefault()

        setHilite(indx)
        mongo.collection('users')
        .find({"email":emailAdress})
        .then(([{
            name,
            email,
            favs,
            purchased
        }]) => {

            setDetails({
                name,
                email,
                favs,
                purchased
            })
        })
        .catch(console.log('err email'))
    }

    let {
        name,
        email,
        favs,
        purchased
    } = details || null

    useEffect(() => {
        mongo.collection('users')
        .find({"name":"user-emails"})
        .then(results => {
            setUserEmails(results[0].emails)
        })
        .catch(console.log('err emails'))

    }, [mongo])

    return <MainCont>
        <header className={'header'}>
            <h1>View User Data</h1>
        </header>
        <main className={'main'}>
            <section className={'section'}>
                <article className={'article-l'}>
                <h3>Please choose an Email to view details.</h3>
                    <ul className={'ul-l'}>
                        {userEmails.map((email,i) => (
                            <Lil key={'email'+i} onClick={getDetails(email, i)} clicked={i === hilite ? 'true' : null}>{email}</Lil>
                        ))}
                    </ul>
                </article>
                <article className={'article-r'}>
                    {
                        name
                        ?   <ul>
                                <li>Email:<br/>{email}</li>
                                <li>Saved Favs:<br/> {favs.length}</li>
                                <li>Purchased:<br/> {purchased.length}</li>
                            </ul>
                        :   <h3>Click on an Email to get details.</h3>
                    }
                </article>
            </section>
        </main>
    </MainCont>
}