import styled, {css} from 'styled-components'

const basic = css`
	border: 1px solid rgba(14,22,38,0.5);
	border-radius:5px;
	padding: 0.5em;
	font-family: 'Roboto Slab', serif;
	font-size:1em;
    background: rgba(14,22,38,0.1);
    color:rgba(14,22,38,1);
    margin:0.3em 0.5em;
    flex-grow:1;
    width:100%;

	&:focus{
      background: white;
    }
`
export const TopCont = styled.div` 
    width:100%;
    //height:15%;
    padding:30px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
`
export const BotCont = styled.div` 
    width:100%;
    //height:85%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-bottom:100px;
`
export const FormWrapper = styled.div` 
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:clamp(300px, 80%, 700px);

    .forgot{
        align-self:flex-end;
        font-size:0.8rem;
        color:rgba(14,22,38,0.8);
        margin:0 15px 5px;
        cursor:pointer;
    }
`
export const Title = styled.span`
    font-size:clamp(1.7rem, 5vw, 2.3rem);
    color:rgba(14,22,38,1);
`
export const Label = styled.label`
    position:relative;
    display:flex;
    flex-flow:wrap;
    align-items:center;
	margin: 1px;
    border-radius:3px;
	padding: 10px;
    padding-left:25px;
	font-family: 'Roboto Slab', serif;
	font-size:1em;
    background: rgba(255,255,255,0.5);
`
export const ApiEventText = styled.div`
	margin:5px;
	font-size:1em;
    background:rgba(255, 230, 128, 1);
    padding:15px;
    //text-align:center;
`
export const BtnLabel = styled.label`
    position:relative;
    display:flex;
    flex-flow:wrap;
    align-items:center;
	font-family: 'Roboto Slab', serif;
	font-size:1em;
`
export const BtnLabelCont = styled.div`
    display:flex;
    flex-flow:wrap;
    justify-content:space-between;
`
export const Text = styled.input.attrs(props => ({
	type: "text"
}))`
    ${basic}
  `
  export const TextArea = styled.textarea.attrs(props => ({
	type: "textarea"
}))`
    ${basic}
  `
export const Password = styled.input.attrs(props => ({
	type: "password"
}))`
    ${basic}
  `
export const Number = styled.input.attrs({
	type: "number",
	step:"any"
})` 
    ${basic}
    flex-grow:0;
  `