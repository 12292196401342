import {useState} from 'react'
import {Form, Search, SearchSubmitBtn, SearchLoader} from './formStyles'
import { FiSend } from 'react-icons/fi'


export default function SearchBar({
    apiEvent,
    searchValue
}){
    const [changed, setChanged] = useState('')

    return <Form onSubmit={searchValue(changed)}>
                <Search
                type='search'
                name='sea'
                minlength="3"
                maxlength="50"
                placeholder={`Search for minerals and more.`}
                onChange={e => setChanged(e.target.value)} 
                value={changed}
                autoFocus
                />
                <SearchSubmitBtn type={'submit'}>
                    {apiEvent.loading && apiEvent.name === 'search' && <SearchLoader />}
                    <FiSend color={'rgba(14,22,38,0.5)'} size={24} />
                </SearchSubmitBtn>
            </Form>
}