import {Image, Transformation} from 'cloudinary-react'
import { Li, FavTitle, Remove, OpenItem, Loader} from './favStyles'
import { FiX } from 'react-icons/fi'

export default function Fav({
    fav,
    openItem, 
    deleteItem,
    i,
    loading,
    selected
}){
    let {
        _id,
        //reference, 
        //name, 
        //classification, 
        //location, 
        //measurements,
        //points,
        image, 
        title, 
        //desc, 
        //price
    } = fav
    
    return <Li>
         <OpenItem onClick={openItem(i)}>
            <Image cloud_name='rinchen' publicId={image[0]} >
                <Transformation width="100" crop="scale" /> 
            </Image>
            <FavTitle>{title}</FavTitle>
        </OpenItem>
        <Remove onClick={deleteItem(_id, i)}>
            {loading && selected === i ? <Loader /> : <FiX color={'black'} size={24} />}
        </Remove>
    </Li>
}