import styled from 'styled-components'

export const Select = styled.select.attrs(props => ({
	type: "select"
}))`
    border: 1px solid rgba(14,22,38,0.5);
	border-radius:3px;
	padding: 8px;
	font-family: 'Roboto Slab', serif;
	font-size:1em;
    color:rgba(14,22,38,1);
    margin:0.3em 0.5em;

	&:focus{
      background: white;
    }
  `
export const Option = styled.option`

`
export const CollCont = styled.div`
    width:100%;
    padding:30px 15px 15px;
    background:rgba(14,22,38,0.1);
    margin-bottom:30px;
    display:flex;
    align-items:flex-end;
    flex-direction:column;
    border-bottom: 1px solid rgba(14,22,38,0.3);
`
export const BtnCont = styled.label`
    //width:100%;
    display:flex;
    align-items:center;
    margin-bottom:10px;
`