import {useState, useEffect} from 'react'
import {SectionBtns, SectionBtn, SliderCont} from './homeStyles'
import Slider from '../slider/Slider'
import {FiHeart, FiTarget } from 'react-icons/fi'
        
export default function HomeSlider({
    useHistory,
    itemColls,
    setItems,
    setFromHome
}){

    const history = useHistory()

    const [selected, setSelected] = useState([true, false])
    const [imageArr, setImageArr] = useState([])
    const [sectionIndx, setSectionIndx] = useState(0)
    const [currItems, setCurrItems] = useState([])

    let sections = indx => e => {
        e.preventDefault()
        setSelected(state => (state.map((s,i) => i === indx ? true : false)))
        //setImageArr(indx === 0 ? itemColls.ourFavImgs : itemColls.recent)
        setSectionIndx(indx)
    }

    let urlClick = indx => e => {
        e.preventDefault()
        let first = currItems.slice(0)
        first.splice(indx, 1)
        first.unshift(currItems[indx])
        setItems(first)
        history.push(`/mineral/${ indx === 0 ? 'best' : 'new'}`)
    }

    useEffect(() => {
        let sortImgsWithIds = itemColls[itemColls.names[sectionIndx]].map((id,i) => {
            let sorted = itemColls[itemColls.names[sectionIndx]+'Itms']
                    .filter(itm => (id.toString() === itm._id.toString()))
    
            return sorted[0]
        })
        
        setCurrItems(sortImgsWithIds)
        setImageArr(sortImgsWithIds.map(itm => (itm.image[0])))
	}, [itemColls, sectionIndx])

    useEffect(() => {
        setFromHome(true)
	}, [setFromHome])
    
    return <>
        <SectionBtns>
            <SectionBtn selection={selected[0]} onClick={sections(0)}>
                <FiHeart color={'white'} size={17} style={{marginRight:'5px'}} /> 
                Our Favorites
            </SectionBtn>
            <SectionBtn selection={selected[1]} onClick={sections(1)}>
                <FiTarget color={'white'} size={17} style={{marginRight:'5px'}} /> 
                Recent Acquisitions
            </SectionBtn>
        </SectionBtns>
        <SliderCont>
            <Slider {...{imageArr, urlClick}}/>
        </SliderCont>
    </>
}