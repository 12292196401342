import {useRef} from 'react'
import {PayPalButtons} from "@paypal/react-paypal-js"

export default function Checkout({cart, setCheckout, verified}) {

	const sumTotal = useRef('')
	const itms = useRef([])
	
	sumTotal.current = cart.reduce((a,b) => (a + b.price), 0).toString()
	
	itms.current = [...cart.map((itm,i) => ({
			name: "Item " + i,
			description: itm._id.toString(),
			sku: itm.reference,
			unit_amount: {
				currency_code: "GBP",
				value: itm.price.toString()
			},
			quantity: "1"
		}))	
	]

	const createOrder = (data, actions) => {

        return actions.order
			.create({
				purchase_units:[
					{
						reference_id: "Rinchen Minerals",
						description: "Discover a World of Gems",

						custom_id: verified.customData ? verified.email : "Unregistered",
						soft_descriptor: verified.customData ? "Registered" : "Unregistered",
						amount: {
							currency_code: "GBP",
							value: sumTotal.current,
							breakdown: {
								item_total: {
									currency_code: "GBP",
									value: sumTotal.current
								}
							}
						},
						items: itms.current,
					}
				],
				application_context: {
					shipping_preference: "NO_SHIPPING",
				},
			})
			.then((orderID) => {
				return orderID
			})
	}

	const onApprove = (data, actions) => {
		return actions.order.capture().then(function (details) {

			const {id, purchase_units:[{
					custom_id, 
					amount,
					payee,
					items
				}],
				payer,
				create_time
			} = details

			setCheckout({
				id,
				user: custom_id, 
				amount:amount.breakdown, 
				payee, 
				items, 
				payer, 
				create_time
			})

		}).catch(err=> console.log("Something went wrong."))
	}

  	return <PayPalButtons      
	  createOrder={createOrder}
	  onApprove={onApprove} 
	  />
}
