import {insertOne} from '../realm/insertUpdateFind'
import {TopCont, BotCont, Text, TextArea, Number, Label, BtnLabel, FormWrapper, Title} from './addEditStyles'
import useForm from './useForm'
import {adminItem} from './forms'
import Btn from '../buttons/Btn'
import { FiFilePlus } from 'react-icons/fi'

export default function AddItem ({
    apiEvent,
    setApiEvent,
    mongo
}){

    let reset = () => Object.keys(state).map(key => (changeHandler({target:{value:'', id:key}})))

    let successCallback = (success, result) => {

        setApiEvent(state => ({...state, loading:false, name:'add'}))

        if(success){
            reset()
            setApiEvent(state => ({...state, text:`Successfully inserted item with _id: ${result.insertedId}`}))
        }else{
            setApiEvent(state => ({...state, text:`Failed to insert item. Try again.`}))
        }
            
            setTimeout(() => {setApiEvent(state => ({...state, text:'Add another one or close.'}))}, 5000)
    }

    const { state, submitHandler, changeHandler } = useForm(adminItem(), values => {

        setApiEvent(state => ({...state, name:'', loading:true}))

        let tags = values.tags.split(',')
        let images = values.image.split(',')
        values.tags = tags.map(tag => (tag.toLowerCase().trim()))
        values.image = images.map(img => (img.trim()))
        insertOne(mongo, 'items', values, successCallback)
    })

    return <>
        <TopCont><Title>Add a new item.</Title></TopCont>
        <form onSubmit={submitHandler}>
            <BotCont>
                <FormWrapper>
                    {Object.keys(state).map(key => (
                        (state[key].type === 't1' &&
                        <Label key={key}>{state[key].label}
                            <TextArea 
                            name ="t1"
                            size="1em"
                            minlength="3"
                            maxlength="500"
                            rows="3"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't2' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t2"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't3' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t3"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't4' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t4"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't5' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t5"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't6' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t6"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't7' &&
                        <Label key={key}>{state[key].label}
                            <Text 
                            name ="t7"
                            size="1em"
                            minlength="3"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label> )||
                        (state[key].type === 't8' &&
                        <Label key={key}>{state[key].label}
                            <TextArea 
                            name ="t8"
                            size="1em"
                            minlength="4"
                            maxlength="500"
                            rows="3"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>) ||
                        (state[key].type === 't9' &&
                        <Label key={key}>{state[key].label}
                            <Text
                            name ="t9"
                            size="1em"
                            minlength="6"
                            maxlength="100"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>) ||
                        (state[key].type === 't10' &&
                        <Label key={key}>{state[key].label}
                            <TextArea
                            name ="t10"
                            size="1em"
                            rows="5"
                            minlength="6"
                            maxlength="5000"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>) ||
                        (state[key].type === 'n1' &&
                        <Label key={key}>{state[key].label}
                            <Number
                            name ="n1"
                            size="1em"
                            minlength="6"
                            maxlength="18"
                            placeholder={state[key].label}
                            id={key} 
                            onChange={changeHandler} 
                            value={state[key].value} 
                            />
                        </Label>)
                        ))}
                        <BtnLabel>
                            <Btn 
                            text={'ADD'}
                            maxW={'150'} 
                            loading={apiEvent.loading}
                            icon={<FiFilePlus color={'black'} size={21} />}
                            />
                            {apiEvent.name === 'add' && apiEvent.text}
                        </BtnLabel>
                    </FormWrapper>
                    </BotCont>
                </form>
            </>
}