import {useState, useEffect, Fragment} from 'react'
import Btn from '../buttons/Btn'
import {TopCont, Title, Group, Ul, Li, Lip, CartBtn, MidCont, Desc} from './itemLevelAStyles'
import Slider from '../slider/Slider'
import { FiPlus, FiHeart } from 'react-icons/fi'
import {updateUserData, find} from '../realm/insertUpdateFind'
import {useParams } from "react-router-dom"

export default function ItemLevelA({
    items, 
    itemIndx,
    setOpenCart, 
    setCart, 
    isItemInCart, 
    setIsItemInCart,
    mongo,
    setVerified,
    verified,
    favItems,
    setFavItems,
    setOpenVerified,
    favItemIndx,
    setFavItemIndx
}) {
    
    const { item_index } = useParams()
    const [isFavMatch, setIsFavMatch] = useState(false)
    const [loading, setLoading] = useState(false)
    
    let {
        _id,
        reference, 
        name, 
        classification, 
        location, 
        measurements,
        points,
        image, 
        title, 
        desc, 
        price
    } = verified.favs && favItemIndx.active ? favItems[favItemIndx.indx] : items[item_index]

    let isAdded = (isItemInCart.length && 
        isItemInCart.find(v => v === reference)) ||
        price === 0  ? true : false

    let getUpdatedFavs = () => {
        
        //console.log(_id.getTimestamp())// time stamp on all ids

        let findCallback = (success, results) => {
            
            setLoading(false)
            if(success){
                setOpenVerified(true)
                setFavItems(state => ([...state, ...results]))
            }else{
                alert('Failed, please try again.')
            }
        }

        let getItems = {
            "_id" : _id
        }

        find(mongo, 'items', getItems, findCallback)
    }

    useEffect(() => {
        verified.favs && 
        setIsFavMatch(verified.favs.some(fav => (fav.$oid === _id.toString())))

    },[_id, verified.favs, favItemIndx.active, setFavItemIndx])
    
    return <>
        <TopCont>
            <Slider imageArr={image} />
        </TopCont>
        <MidCont>
            <Group>
                <Title>{title}</Title>
                <CartBtn>           
                    <Btn
                    disabled={isAdded}
                    click={e => {
                        e.preventDefault()
                        setIsItemInCart(state => [...state, reference])
                        setOpenCart(state => !state ? true : state)
                        setCart(state => [...state, verified.favs && favItemIndx.active 
                            ? favItems[favItemIndx.indx] 
                            : items[itemIndx]
                        ])
                    }} 
                    text={ isAdded ? 'ADDED TO CART' : 'ADD TO CART'}
                    icon={<FiPlus color={'black'} size={24} />}
                    />
                    {verified.favs && price !== 0 &&
                        <Btn
                        disabled={isFavMatch}
                        loading={loading}
                        click={e => {
                            e.preventDefault()
                            setLoading(true)
                           
                            let userDataCallback = success => {
                                if(success && !isFavMatch){
                                    getUpdatedFavs()
                                    setOpenCart(true)
                                    setOpenVerified(true)

                                    setVerified(state => {
                                        state.favs.push({$oid:_id.toString()})
                                        return state
                                    })
                                }                                
                                setIsFavMatch(verified.favs.some(fav => (fav.$oid === _id.toString()))) 
                            }

                            let insertFav = {"$addToSet": {"favs": _id}}

                            updateUserData(mongo, 'users', { "userId": verified.id }, insertFav, userDataCallback)
                        }} 
                        text={isFavMatch ? 'SAVED!' : 'SAVE TO FAVS'}
                        icon={isFavMatch ? <FiHeart color={'gray'} size={24} /> : <FiHeart color={'red'} size={24} />}
                        />
                    }
                </CartBtn>
            </Group>
            <Group>
                <Desc>
                    {desc}
                </Desc>
                <Ul>
                    <Li><Lip>Name:<br/> {name}</Lip></Li>
                    <Li><Lip>Classification:<br/> {classification}</Lip></Li>
                    <Li><Lip>Location:<br/> {
                        location.split(',').map(loc => (
                            <Fragment key={loc}>- {loc.trim()}<br /></Fragment>
                        ))}
                    </Lip></Li>
                    <Li><Lip>Approximate measurements:<br/> {measurements}</Lip></Li>
                    <Li><Lip>Points of interest:<br/> {points}</Lip></Li>
                    <Li><Lip>Reference:<br/> {reference}</Lip></Li>
                    <Li><Lip>Price:<br/> {price > 0 ? `£ ${price} `: <span>SOLD</span>}</Lip></Li>
                </Ul>
            </Group>
        </MidCont>
    </>
}