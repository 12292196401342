import {useCallback, useEffect, useState, useRef} from 'react'
import {CustomSelect, TxtCont, SearchSelectTxt, SearchSelectBtn} from './formStyles'
import {getCollectionDataLists} from '../realm/insertUpdateFind'
import Search from './Search'
import { FiList, FiSearch } from 'react-icons/fi'

export default function SearchBar({
    mongo,
    dataList, 
    setDataList,
    apiEvent,
    setApiEvent,
    useHistory,
    setItems,
    setFromHome,
    setGotItemsIcns
}){

    const [isSearch, setIsSearch] = useState(true)
    const [options, setOptions] = useState([])

    const history = useHistory()

    const lastSearch = useRef('')
    const once = useRef(false)

    const selectValue = e => {
        if(!e) return
        pushValue(e.value)
    }

    const searchValue = val => e => {
        e.preventDefault()
        if(['search', 'confirmation', 'reset', 'about', 'shipping'].some(name => (name === val))){
            setApiEvent(state => ({...state, loading:false, text:`Invalid word. Try again!`}))
            return
        }else if(/^[a-zA-Z-\d\s:]+$/.test(val)){
            pushValue(val)
        }else{
            setApiEvent(state => ({...state, loading:false, text:`Please remove all special characters except dashes.`}))
        }
    }

    const pushValue = val => {
        setGotItemsIcns && setGotItemsIcns(true)
        apiEvent.loading && setApiEvent(state => ({...state, name:'search', loading:false}))

        if(val === lastSearch.current || apiEvent.loading) return

        setItems([])
        setFromHome && setFromHome(false)
        
        lastSearch.current = val

        setApiEvent(state => ({...state, loading:true, text:`Searching minerals...`}))

        history.push(`/mineral/${val.toLowerCase()}`)
    }

    let listCallback = useCallback((success, result) => {
        if(success){
            setDataList(state => (result.list))
        }else{
            setApiEvent(state => ({...state, loading:false, text:`Problem loading, please refresh the page.`}))
        }
    }, [setApiEvent, setDataList])

    useEffect(() => {
        if(!once.current && mongo.collection){
            once.current = true
            getCollectionDataLists(mongo, 'data_lists', { "name":"main"}, listCallback)
        }
    },[mongo.collection, listCallback, mongo])

    useEffect(() => {
        options.length <= 0 && dataList.length >= 1 && 
        setOptions(dataList.map((val,i) => ({value:val.toLowerCase(), label:val})))
    },[dataList, options])

    return <TxtCont>
                <SearchSelectTxt>
                    <SearchSelectBtn 
                    isSearch={!isSearch}
                    onClick={e => {e.preventDefault(); setIsSearch(false)}}>
                        <FiSearch color={!isSearch ? 'white' : 'rgba(14,22,38,0.7)'} size={24} />
                    </SearchSelectBtn>
                    <SearchSelectBtn 
                    {...{isSearch}} 
                    onClick={e => {e.preventDefault(); setIsSearch(true)}}>
                        <FiList color={isSearch ? 'white' : 'rgba(14,22,38,0.7)'} size={24} />
                    </SearchSelectBtn>
                </SearchSelectTxt>
                {isSearch
                    ?   
                        <CustomSelect
                        classNamePrefix={'Select'}
                        placeholder={`Select minerals...`}
                        isLoading={apiEvent.loading}
                        onChange={selectValue}
                        options={options}
                        autoFocus
                        isClearable
                        theme={theme => ({
                            ...theme,
                            //borderRadius: '30px',
                            colors: {
                                ...theme.colors,
                                primary25: 'rgba(14,22,38,0.1)',
                                primary: 'rgba(14,22,38,0.5)',
                                //neutral0:'rgba(255, 221, 85, 0.1)',
                                //neutral20:'rgba(255, 221, 85, 0.5)',
                                //neutral50:'rgba(255, 221, 85, 0.1)',
                                //neutral80:'rgba(255, 221, 85, 0.1)'
                            },
                            })}
                        />                        
                    :
                        <Search 
                        {...{
                            apiEvent,
                            searchValue
                        }}
                        />
                }
            </TxtCont>
}