import styled from 'styled-components'
import {animated} from '@react-spring/web'

export const MainCont = styled.div`
	position:relative;
    overflow:hidden;
    display: flex;
    flex-flow: column-reverse;
`
export const NavCont = styled.div`
	position:relative;
    overflow-x:hidden;
`
export const PosBtn = styled.button`
    position:fixed;
    bottom:10px;
    right:10px;
    padding:10px;
    display:flex;
    align-items:center;
    justify-content:center;
`
export const PosBtnBg = styled.div`
    position:absolute;
    border-radius:50%;
    width:100%;
    height:100%;
    background:rgba(255,255,255,0.5);
    z-index:-1;
`
export const FullScreenClose = styled(animated.div)`
	position:fixed;
	top:0;
	left:0;
  	right:0;
	bottom:0;
    background:rgba(14,22,38,0.5);
	cursor:pointer;
`