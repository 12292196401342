import styled from 'styled-components'
import {animated, useSpring} from '@react-spring/web'

export function useFullScreenClose(){
    const [closeSpring, setCloseSpring] = useSpring(() =>({opacity:'0', visibility:'hidden'}))
    return {closeSpring,setCloseSpring}
}

export const Close = styled(animated.div)`
	position:fixed;
	top:0;
	left:0;
  	right:0;
	bottom:0;
	cursor:pointer;
	background:rgba(14,22,38, 0.5);
`
export const CartCont = styled(animated.div)`
	position:fixed;
	top:0;
	left:0;
  	right:0;
	height:auto;
	max-height:calc(100vh - 70px);
  	margin-left: auto;
  	margin-right: auto;
	width:clamp(300px, 80%, 800px);
	background:rgba(255,255,255, 0.95);
	border-radius:0 0 5px 5px;
	overflow-y:auto;
`
export const CartTitle = styled.p`
	display:flex;
    align-items:center;
	padding:35px 10px 15px 30px;
	font-size: clamp(1rem, 2.2vw, 1.3rem);
	color:white;
	background:rgba(78, 154, 6, 0.6);
`
export const CartItems = styled.ul`
	max-height:300px;
	overflow-x:hidden;

	> * {
      &:last-child {
		border-bottom: 1px solid rgba(78, 154, 6, 0.5);

      }
    }
`
export const CartItem = styled.li`
	display:flex;
	//margin-bottom:1px;
	border-top: 1px solid rgba(78, 154, 6, 0.5);


`
export const Thumb = styled.div`
	min-width:50px;
	height:50px;
	overflow:hidden;
	display:flex;
    align-items:center;
    justify-content:center;
	flex-grow:0;
`
export const Title = styled.p`
    display:flex;
    align-items:center;
	font-size: clamp(0.8rem, 2.2vw, 1.1rem);
	flex-grow:1;
	padding-left:10px;
	background:rgba(78, 154, 6, 0.1);
`
export const Price = styled(Title)`
	background:rgba(78, 154, 6, 0.05);
	flex-grow:0;
	padding:10px;
	white-space:nowrap;
`
export const Remove = styled(Title)`
	background:rgba(78, 154, 6, 0.3);
	flex-grow:0;
	padding:10px;
	cursor:pointer;
`
export const DetailsCont = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
	background:rgba(78, 154, 6, 0.05);
	padding:20px;
`
export const PriceFinal = styled.p`
	font-size: clamp(1rem, 2.2vw, 1.3rem);
`
export const CheckoutCont = styled.div`
    display:flex;
	width:100%;
    align-items:center;
    justify-content:flex-end;
	padding:10px 5px 5px;
	background: rgba(78, 154, 6, 0.1);

`
