import {useState} from 'react'
import {useHistory} from "react-router-dom"
import {TopCont, Title, BotCont, Ul } from './favStyles'
import {updateUserData} from '../realm/insertUpdateFind'
import { FiHeart } from 'react-icons/fi'
import Fav from './Fav'

export default function Favs({
    verified, 
    favItems, 
    setFavItems,
    favItemIndx,
    setFavItemIndx, 
    setVerified, 
    setOpenOverlayA,
    mongo
}){
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(0)
    const history = useHistory()

    let openItem = indx => e => {
        e.preventDefault()
        setFavItemIndx({active:true, indx, isFav:false})
        setOpenOverlayA(true)
        history.push(`/mineral/${favItems[indx].tags[0]}/0`)
    }

    let deleteItem = (id, indx) => e => {
        e.preventDefault()
        setSelected(indx)
        setLoading(true)
        if(favItemIndx.indx === indx){
            setOpenOverlayA(false)
            //let pathname = history.location.pathname.slice(1)
            //history.push(`/${pathname.slice(0, pathname.indexOf('/'))}`)
        }

        let delCallback = (success, results) => {
            setLoading(false)
            if(success){
                setFavItems(state => (state.filter(fav => (fav._id.toString() !== id.toString()))))
                setVerified(state => {
                    state.favs = state.favs.filter(fav => (fav.$oid.toString() !== id.toString()))
                    return state
                })
            }else{
                alert('Could not delete, please try again.')
            }
        }

        let delItem = { "$pull": { "favs": id } }
    
        updateUserData(mongo, 'users', { "userId": verified.id }, delItem, delCallback)
    }

    return <>
        <TopCont>
            <Title>
                <FiHeart  color={'rgba(239,41,41,1)'} size={35} style={{marginRight:'10px'}} />
                    {`${verified.customData.name} you have "${favItems.length}" favorites saved!`}
            </Title>
        </TopCont>
        <BotCont>
            <Ul>
                {
                    favItems && favItems.map((fav,i) => (
                        <Fav 
                        key={`fav${i}`} {...{fav, openItem, deleteItem, i, selected, loading}}
                        />
                    )).reverse()
                }
            </Ul>
        </BotCont>
    </>

}