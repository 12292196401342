import styled, {css, keyframes} from 'styled-components'

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`
  export const SubmitLoader = styled.div`
	border: 2px solid rgba(14,22,38,0);
	border-top: 2px solid rgba(14,22,38,1);
	border-radius: 50%;
	width: 35px;
	height: 35px;
	animation: ${spin} 1s linear infinite;
` 
export const SubmitBtns = styled.button`
	position:relative;
    border-radius:3px;
    cursor: pointer;
	background:white;
    margin:1px;

	&:hover{
		background:rgba(14,22,38,0.1);
	}
` 
export const BtnTxt = styled.div`
    padding:10px;
`
const basic = css`
	border: 1px solid rgba(14,22,38,0.5);
	border-radius:5px;
	padding: 0.5em;
	font-family: 'Roboto Slab', serif;
	font-size:1em;
    background: white;
    color:black;
    //margin:10px;
    flex-grow:1;
    width:100%;

	&:focus{
      background: white;
    }
`
export const TopCont = styled.div` 
    width:100%;
    height:20%;
    padding:30px;
    display:flex;
    align-items:center;
    justify-content:center;
`
export const BotCont = styled.div` 
    width:100%;
    height:80%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding-bottom:100px;
`
export const BtnGrp = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-evenly;
	width:100%;
    margin:10px 10px 25px 10px;
`
export const Ul = styled.ul`
    max-height:100%;
    overflow:auto;
    padding:5px;
`
export const Li = styled.li`
	width:100%;
	padding:10px 15px;
	border-radius:3px;
	cursor: pointer;
    //color:rgba(14,22,38,0.9);
	background:white;
    margin:1px;

	&:hover{
		background:rgba(14,22,38,0.1);
	}
`
export const Form = styled.form`
	display:flex;
    flex-direction:column;
    align-items:center;
	width:clamp(300px, 50%, 1000px);
`
export const Title = styled.span`
    font-size:clamp(1.7rem, 5vw, 2.3rem);
    color:rgba(14,22,38,0.7);
`
export const Text = styled.input.attrs(props => ({
	type: "text"
}))`
    ${basic}
  `